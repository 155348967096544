<template>
  <!-- toolbar on the top -->
  <WfmToolbar
    custom-class="toolbar-class ps-2"
    :data-test-id="'list_' + folder?.name"
  >
    <template #left-utility>
      <div class="d-flex">
        <WfmActionsList
          v-if="folder?.name!='leaves'"
          :actions="actionsToPerform.length>0?actionsToPerform:null"
          :data-test-id="'list_' + folder?.name"
          @action-click="performAction"
        />
        <div
          v-if="folder?.name=='leaves'"
          class="my-auto ms-2"
        >
          <v-icon
            data-test-id="leaves-calendar-icon"
            name="bi-calendar3"
            scale="1.5"
            @click="openAdminCalendar()"
          />
        </div>

        <Transition name="slide-fade">
          <div
            v-if="showFilters"
            class="ms-3"
          >
            <WfmListDateFilters
              v-if="showFilters"
              :folder="folder"
              @apply-filters="applyDateFilters"
            />
          </div>
        </Transition>
      </div>
    </template>
    <template #right-utility>
      <div class="me-2 d-flex">
        <WfmGlobalSearch
          search-input-class="list-search-class"
          placeholder="Search List..."
          :loading="searchLoading"
          :data-test-id="listSearchTestId"
          @handle-search="handleSearch"
        />
        <button @click="toggleFilterView()">
          <v-icon
            :name="filterIcon"
            scale="1.6"
            class="my-auto ms-2"
            :fill="`var(--date-filter-color)`"
          />
          <v-tooltip
            activator="parent"
            location="bottom"
          >
            {{ !showFilters ? "show date filters" : "hide date filter" }}
          </v-tooltip>
        </button>
      </div>
    </template>
  </WfmToolbar>

  <!-- list items  -->
  <WfmListItems
    :list-folder="folder"
    :row-double-clicked="handleRowClick"
    :pagination="true"
    :suppress-pagination-panel="true"
    :search-term="searchTermToPass"
    :search-path="searchPath"
    :current-task="currentTaskName"
    :fields-to-filter="fieldsToFilter"
    :current-row-data="currentRowData"
    :refresh-list="refreshList"
    @send-grid-api="getGridApi"
  />
  <!-- dependancy delete confirm -->
  <!-- <WfmModal
    v-if="deleteRecord"
    @close="deleteRecord=false"
  >
    <template #header>
      <div class="mx-auto my-auto">
        <h6>Delete Confirmation</h6>
      </div>
    </template>
    <template #body>
      <div
        class="msg-body"
        :data-test-id="folder?.name+'/list/depandentList'"
      >
        <div>
          There are records which are dependent on the
          {{ folder?.label?.eng }} you are deleting.
        </div>
        <div
          v-for="(item,index) in currentRowNode"
          :key="index"
        >
          <div class="delete-list mt-1 p-2 d-flex justify-space-between">
            <div>{{ index+1 }} : {{ item.data?.name?.eng || item.data?.name || item.data?.code }}</div>
            <div>
              <v-icon
                id="remove-btn"
                :data-test-id="folder?.name+'/deleteBtn/'+index"
                class="text-danger"
                name="bi-trash"
                scale="1"
                @click="removeFromSelected(item)"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div
        class="d-flex justify-space-between"
        :data-test-id="folder?.name/'list/dependantDelete'"
      >
        <WfmButton
          button-class=" ms-1 save-btn me-2"
          data-test-id="cancleDelete"
          @button-click="cancelDelete()"
        >
          cancel
        </WfmButton>
        <WfmButton
          data-test-id="confirmDelete"
          button-class=" ms-1 save-btn me-2"
          @button-click="dependencyDelete()"
        >
          Delete Anyway
        </WfmButton>
      </div>
    </template>
  </WfmModal> -->
  <!-- modal to open for bulk upload -->
  <div v-if="showInModal">
    <UploadForm
      v-if="bulkUploadForm"
      :current-task="currentTaskName"
      :txn-to-use="txnToUse"
      :ag-grid-api="gridApi"
      @close-upload="closeUpload"
    />
  </div>
</template>

<script>
import { ref, computed} from 'vue'
import { useStore } from 'vuex'
import WfmListItems from '../../common/wfm-list-items.vue';
import { toast } from 'vue3-toastify'
import WfmToolbar from '../../common/wfm-toolbar.vue'
import WfmGlobalSearch from '../../common/wfm-global-search.vue'
import { sendMessage } from '../../services/websocket'
import getFolders from '../../composables/getFolders'
import getFormInfo from '../../composables/getFormInfo'
import getFatRow from '../../composables/fatRowGenerator'
import postData from '../../composables/postData'
import processFormData from '../../composables/processFormData'
import makeTxn from '../../composables/makeTxn'
import makeTxnForBulk from '../../composables/makeTxnForBulk'
import actionsListInFolder from '../../composables/actionsListInFolder'
import makeFilter from '../../composables/makeFilter'
import foldersUtility from '../../composables/foldersUtility';
import WfmModal from '../../common/wfm-modal.vue'
import WfmButton from '../../common/wfm-button.vue'
import UploadForm from './UploadForm.vue'
import getUtility from '../../composables/getUtility'
import WfmListDateFilters from '../../common/wfm-list-date-filters'
import WfmActionsList from '../../common/wfm-actions-list.vue';
import useIcons from '../../composables/getIcons'
import getConverters from '../../composables/getConverters'
import { usePaths } from '../../composables/useFilePath';
export default {
  name: 'FolderList',
  components: {
    WfmListItems,
    WfmToolbar,
    WfmGlobalSearch,
    WfmModal,
    WfmButton,
    UploadForm,
    WfmListDateFilters,
    WfmActionsList
  },
  props:{
    name:{
      type:String,
      default:null
    },
    readOnly:{
      type:Boolean,
      default:false
    },
    folder:{
      type:Object,
      default:null
    },
    searchTerm:{
      type:String,
      default:null
    },
    searchPath:{
      type:String,
      default:null
    },
    fieldsToFilterOn:{
      type:Array,
      default:null
    }

  },
  emits:['add-tab'],
  setup(props, context) {
    //variables
    const store = useStore()
    const bulkUploadForm = ref(false);
    const txnToUse = ref(null)
    const currentRowData = ref(null)
    const currentRowNode = ref([])
    const notificationId = ref()
    const searchTermToPass = ref(props.searchTerm)
    const mainFolderId = ref()
    const fieldsToFilter = ref(props.fieldsToFilterOn)
    const currentTaskName = ref(`${props?.folder?.name}_list`)
    const refreshList = ref(false)
    const list = ref()
    const { getFormObject, getCurrentTxn } = getFormInfo()
    const {getIcon} = useIcons()
    const deleteTxn = ref(false)
    const isBulkTxn = ref(false)
    const searchLoading = ref(false)
    const deleteRecord = ref(false)
    const forceDelete = ref(false)
    const sessionId = store.getters['sessionIdGetter'];
    const bSettings = store.getters['bSettings'];
    //computed
    const { emptyTheContainer } = processFormData()
    const { getFoldersList} = getFolders();

    // const folderList = folderListToUse(bSettings);
    // const personsFolder = computed(() => {
    //   return getCurrentFolder('persons', folderList)
    // })
    // })
    const showFilters = ref(false)
    const filterIcon = computed(() => {
      return showFilters.value ? 'bi-calendar-minus' : 'bi-calendar2-plus'
    })
    const listSearchTestId = computed(() => {
      return `${props?.folder?.name}/list/search-bar`;
    })
    const folderName = ref(props.folder?.name)

    const gridApi = ref(null)

    const actionsToShow = computed(() => {
      const listOfTxn = props?.folder?.txns;
      const {actionsToShowInList} = actionsListInFolder(listOfTxn)
      const retVal = props?.readOnly ? [] : actionsToShowInList()
      return retVal
    })
    const actions = computed(() => {
      const retVal = {name:'Actions', actions:[]}
      for (var i = 0; i < actionsToShow.value.length; i++) {
        const index = i;
        const iconsName = actionsToShow?.value?.[i]?.label?.eng?.split(' ')[0];
        const obj = {
          name:actionsToShow?.value?.[i]?.label?.eng,
          icon:getIcon(iconsName)?.name,
          fill:getIcon(iconsName)?.fill,
          action:() => {
            runTxn(actionsToShow?.value?.[index])
          }
        }
        retVal.actions.push(obj)
      }
      return retVal
    })
    const actionsInPayslip = computed(() => {
      const retVal = {name:'Actions', actions:[]}
      for (var i = 0; i < actionsToShow.value.length; i++) {
        const index = i;
        const iconsName = actionsToShow?.value?.[i]?.label?.eng?.split(' ')[0];
        const obj = {
          name:actionsToShow?.value?.[i]?.label?.eng,
          icon:getIcon(iconsName)?.name,
          fill:getIcon(iconsName)?.fill,
          action:() => {
            runTxn(actionsToShow.value[index])
          }
        }
        retVal.actions.push(obj)
      }
      const downloadAction =
        {
          name:'Download',
          icon:getIcon('Download')?.name,
          fill:getIcon('Download')?.fill,
          action:() => {
            downloadPayslip()
          }
        }
      const sendMailAction = {
        name:'Send Mail',
        icon:getIcon('Send')?.name,
        fill:getIcon('Send')?.fill,
        action:() => {
          sendEmail()
        }
      }

      retVal.actions.push(downloadAction)
      retVal.actions.push(sendMailAction)
      return retVal
    })

    const actionsToPerform = computed(() => {
      const retVal = []
      if (props.folder?.name === 'payslips') {
        if (actionsInPayslip.value.actions.length > 0) {
          retVal.push(actionsInPayslip.value)
        }
        return retVal
      }
      else {
        if (actions.value.actions.length > 0) {
          retVal.push(actions.value)

        }
        return retVal
      }

    })

    const showInModal = computed(() => {
      if (props.folder?.uiFlags?.modal_view) {
        return true
      }
      else {
        return  false
      }
    })
    // const showInTabs = computed(() => {
    //   if (props.folder?.uiFlags?.tabs_view) {
    //     return true
    //   }
    //   else {
    //     return false
    //   }
    // })
    const formObject = computed(() => {
      //get the current txn object depending on the txntype
      //get the form object that is needed
      const currentTxn = getCurrentTxn(
        currentTaskName.value,
        props.folder
      )
      const formName = currentTxn?.form?.form_name ? currentTxn?.form?.form_name : currentTxn?.form
      return getFormObject(formName, props.folder)
    })
    const formObjectFound = computed(() => (formObject.value ? true : false))

    const fieldsToDisplay = computed(() => {
      //if the form object is found use it otherwise use the currentFolders existing fields
      if (formObjectFound.value) {
        return formObject.value
      } else {
        const fieldsArray = props.folder.fields
        return fieldsArray
      }
    })

    const formBaseParams = computed(() => {
      return {
        rowData : currentRowData.value,
        fieldsToDisplay : fieldsToDisplay.value,
        formObjectFound : formObject.value ? true : false,
        folder : props.folder,
        refBy : refByToShow(),
        txnToUse :txnToUse.value,
        currentTask :currentTaskName.value,
        rowNode :currentRowNode.value,
        readOnly :false,
        agGridApi:gridApi.value,
      }
    })
    async function sendEmail() {
      const { sendMail } = postData()
      const list = await sendMail(props.folder);
      console.log(list)
    }
    function openAdminCalendar() {
      const tabName = 'Employees Leaves'
      const tabsSpec = {
        label: 'calendar',
        component:'LeavesCalendar',
        name:tabName,
        params:{
          readOnly:false,
          folder:props.folder,
          data:null,
          agGridApi:gridApi.value
        }
      }
      context.emit('add-tab', tabsSpec)
    }
    function showCalendarView(data) {
      let tabName = ''
      const leaveDays = data?.leave_days
      const firstDate = leaveDays?.[0]?.leave_period
      const lastDate = leaveDays?.[leaveDays?.length - 1].leave_period
      const { SQLTsRangeToArray, compactRangeString} = getConverters();
      const startDateRange = SQLTsRangeToArray(firstDate)
      const endDateRange = SQLTsRangeToArray(lastDate)
      const dateString = compactRangeString(startDateRange[0], endDateRange[0])
      const empCode = data.employees_id?.[0]?.code
      tabName = empCode + '-' + dateString
      const tabsSpec = {
        label: 'calendar',
        component:'LeavesCalendar',
        name:tabName,
        params:{
          readOnly:false,
          folder:props.folder,
          data:data,
          agGridApi:gridApi.value
        }
      }
      context.emit('add-tab', tabsSpec)

    }

    function toggleFilterView() {
      if (showFilters.value) {
        showFilters.value = false
        if (fieldsToFilter.value?.length > 0) {
          //when date filters not displayed fields to filter  set empty to get full list
          fieldsToFilter.value = []
        }
      }
      else {
        showFilters.value = true
      }
    }
    function downloadPayslip() {
      //selected payslips retrieve
      const selectedPayslips = gridApi.value.getSelectedRows()
      const {payslipsDownload} = getUtility()
      payslipsDownload(selectedPayslips, props.folder, store)
    }
    function getGridApi(api) {
      gridApi.value = api
    }
    function performAction(action) {
      console.log('handle action in the list')
      action()
    }
    async function runTxn(txn) {
      const txnNameArray = txn.label.eng.split(' ')
      if (txnNameArray[0] === 'Create' || txnNameArray[0] === 'Apply') {
        if (txnNameArray[1] === 'Payslips') {
          createPaySlips(txn)
        }
        if (txnNameArray[1] === 'Leaves') {
          createLeaveEntry(txn)
        }
        else {
          createNewEntry(txn)
        }
      }
      else if (txnNameArray[0] === 'Delete') {
        removeRows()
      }
      else if (txnNameArray[txnNameArray.length - 1] === 'Upload') {
        uploadFile(txn)
      }
      else if (txnNameArray[0] === 'Export') {
        exportFolder(txn)
      }
      else if (txnNameArray[0] === 'Generate') {
        generatePayslips(txn)
      }
      else {
        const bulkTxnName = txnNameArray[0] === 'Terminate' ? 'Left' : txnNameArray[0]
        await makeParamsToSend(txn, bulkTxnName)
      }
    }
    function closeUpload() {
      bulkUploadForm.value = false
    }


    //hadleSearch in the gridView list
    function handleSearch(searchTerm) {
      searchTermToPass.value = searchTerm
    }

    function applyDateFilters(data) {
      fieldsToFilter.value = data
    }
    function generatePayslips(txn) {
      currentTaskName.value = 'generate'
      const tabsSpec = {
        label: txn.label.eng,
        component:'PayslipGenerate',
        name:txn.label.eng,
        params:{
          fieldsToDisplay : fieldsToDisplay.value,
          formObjectFound : formObject.value ? true : false,
          folder : props.folder,
          txnToUse :txn,
          currentTask :currentTaskName.value,
          readOnly :false,
          agGridApi:gridApi.value,

        }
      }
      context.emit('add-tab', tabsSpec)
    }
    function createPaySlips(txn) {
      currentTaskName.value = 'create'
      const tabsSpec = {
        label: txn.label.eng,
        component:'PayslipGenerate',
        name:txn.label.eng,
        params:{
          fieldsToDisplay : fieldsToDisplay.value,
          formObjectFound : formObject.value ? true : false,
          folder : props.folder,
          txnToUse :txn,
          currentTask :currentTaskName.value,
          readOnly :false,
          agGridApi:gridApi.value,

        }
      }
      context.emit('add-tab', tabsSpec)
    }
    function createLeaveEntry(txn) {
      currentTaskName.value = 'create'
      const tabsSpec = {
        label: txn.label.eng,
        component:'leavesCreate',
        name:txn.label.eng,
        params:{
          fieldsToDisplay : fieldsToDisplay.value,
          formObjectFound : formObject.value ? true : false,
          folder : props.folder,
          txnToUse :txn,
          currentTask :currentTaskName.value,
          readOnly :false,
        }
      }
      context.emit('add-tab', tabsSpec)

    }
    // function cancelDelete() {
    //   deleteRecord.value = !deleteRecord.value
    // }

    // function dependencyDelete() {
    //   forceDelete.value = true
    //   if (currentRowNode.value.length > 0) {
    //     removeRows()
    //   }
    //   else {
    //     toast.warning('Select rows to delete')
    //   }
    // }
    function removeFromSelected(node) {
      node.setSelected(false);
      currentRowNode.value = gridApi.value.getSelectedNodes()

    }
    async function exportFolder(txn) {
      try {
        const txnToRun = txn
        const params = {
          folder:props.folder.name,
          swhandle:txn.swhandle
        }
        txnToRun.session_key = sessionId;
        txnToRun.params = params
        notificationId.value = toast.loading('exporting folder...', {
          position: toast.POSITION.TOP_CENTER
        })
        const retVal = await sendMessage(txnToRun)
        if (retVal.output.type === 'success') {

          toast.update(notificationId.value, {
            render: 'done',
            autoClose: 1000,
            closeButton: true,
            type: 'export done',
            isLoading: false
          })
          const fileName = retVal.output.data.filename
          const {fileServePath} = usePaths()
          const url = `${fileServePath}/folders/${fileName}`
          // Open the xlsx file in a new tab
          window.open(url, '_blank')
        }
        else {
          toast.update(notificationId.value, {
            render: `Error !${retVal.output.message}`,
            closeButton: true,
            type: 'error',
            autoClose: 6000,
            isLoading: false
          })
        }
      } catch (error) {
        console.log(error)
      }


    }
    async function makeParamsToSend(txn, bulkTxnName) {
      const selectedRows = gridApi.value.getSelectedRows()
      if (selectedRows.length > 0) {
        currentRowNode.value = gridApi.value.getSelectedNodes()
        //start a notification of task progress  with specific id
        notificationId.value = toast.loading('working on it...', {
          position: toast.POSITION.TOP_CENTER
        })
        const dataToChange = {path:'', value:[]}
        let docpSelected = [];
        const txnName = bulkTxnName
        let propertyName = null
        let docpSource = null
        const {changeWorkingFolder} = foldersUtility()
        let docpSourceFolder = null

        if (props.folder.name === 'employees') {
          propertyName = 'employee_status'
          docpSource = props.folder.fields[propertyName].source
          docpSourceFolder = changeWorkingFolder(docpSource, bSettings?.output.data.records[0])
        }
        else if (props.folder.name === 'leaves') {
          propertyName = 'leave_status'
          docpSource = props.folder.fields[propertyName]?.source;
          docpSourceFolder = changeWorkingFolder(docpSource, bSettings?.output.data.records[0])

        }
        else if (props.folder.name == 'payslips') {
          propertyName = 'status'
          docpSource = props.folder.fields[propertyName]?.source;
          docpSourceFolder = changeWorkingFolder(docpSource, bSettings?.output.data.records[0])
        }

        //getting the docpId
        const { generateFilterParams } = makeFilter(txnName, docpSourceFolder, null, null, true);
        const filterParams = generateFilterParams();
        const txnParams = {filters:filterParams, getRowCount:true, refRows:true};
        const { listData } = postData()
        const list = await listData(docpSourceFolder, txnParams);
        if (list.output.type === 'error') {
          console.log(list.output.message);
        } else {
          if (list.output.data.records.length > 0) {
            const { fatRowGenerator } = getFatRow();
            docpSelected = fatRowGenerator(list);
          }
        }
        //loop thru each selected row to create formData
        for (var i = 0; i < selectedRows.length; i++) {
          if (props.folder.name === 'leaves') {
            dataToChange.path = 'leaves.leave_status'
            if (selectedRows[i].leave_status?.[0].id) {
              dataToChange.value.push({oldDocpId:selectedRows[i].leave_status?.[0].id, newId:docpSelected[0]?.id, txnOp:'U', mainFolderId:selectedRows[i].id, propertyName:propertyName, params:selectedRows[i]})

            }
            else {
              dataToChange.value.push({oldDocpId:null, newId:docpSelected[0]?.id, txnOp:'C', mainFolderId:selectedRows[i].id, propertyName:propertyName, params:selectedRows[i]})

            }
          }
          else if (props.folder.name === 'employees') {
            dataToChange.path = 'employees.employee_status'
            if (selectedRows[i][propertyName]) {
              dataToChange.value.push({oldDocpId:selectedRows[i][propertyName][0].id,
                newId:docpSelected[0].id, txnOp:'U', mainFolderId:selectedRows[i].id, propertyName:propertyName, params:selectedRows[i]})
            }
            else {
              dataToChange.value.push({oldDocpId:null, newId:docpSelected[0].id, txnOp:'C', mainFolderId:selectedRows[i].id, propertyName:propertyName, params:selectedRows[i]})

            }
          }
          else if (props.folder.name === 'payslips') {
            dataToChange.path = 'payslips.status'
            if (selectedRows[i][propertyName]) {
              dataToChange.value.push({oldDocpId:selectedRows[i][propertyName][0].id || selectedRows[i][propertyName][0],
                newId:docpSelected[0].id, txnOp:'U', mainFolderId:selectedRows[i].id, propertyName:propertyName, params:selectedRows[i]})
            }
            else {
              dataToChange.value.push({oldDocpId:null, newId:docpSelected[0].id, txnOp:'C', mainFolderId:selectedRows[i].id, propertyName:propertyName, params:selectedRows[i]})

            }
          }
        }
        const formData = dataToChange
        //generate the txnToRun with txn and fieldsData
        const { generateTxn } = makeTxnForBulk(
          formData,
          props.folder,
          txn,
          txnName
        )
        isBulkTxn.value = true
        const txnToRun = generateTxn()
        txnToRun.session_key = sessionId;
        list.value = await sendMessage(txnToRun)
        await handleResult(list.value)
      }
      else {
        toast.warning('please select one or more records')
      }

    }
    //double clicking the rows in grid
    function handleRowClick(params) {
      //if the list is not readOnly and show in modal is not true
      if (!showInModal.value && !props.readOnly) {
        mainFolderId.value = params.data.id
        currentRowNode.value = gridApi.value.getSelectedNodes()
        currentRowData.value = JSON.parse(JSON.stringify(params.data))
        const nameToAdd = currentRowData.value.code || currentRowData.value.employees_id?.[0]?.code || currentRowData.value.shift_code || currentRowData.value?.name?.eng;
        currentTaskName.value = 'update'
        if (props.folder.name === 'reports') {
          const data = params.data
          const tabsSpec = {
            label: data.name,
            component:'ReportsView',
            name:data.label,
            params:{
              readOnly :false,
              dataPassed:JSON.stringify(data)
            }
          }
          context.emit('add-tab', tabsSpec)
        }
        else if (props.folder.name === 'payslips') {
          const data = params.data
          const employeeName = data?.employees_id?.[0]?.persons_id[0]?.first_name?.eng
          const payrollMonth = data?.payroll_month
          const monthOfSlip = new Date(payrollMonth).toLocaleString('default', { month: 'short' });
          const yearOfSlip = new Date(payrollMonth).getFullYear()
          const tabsSpec = {
            label: employeeName,
            component:'PayslipsTemplate',
            name:`${employeeName} ${monthOfSlip}/${yearOfSlip}`,
            params:{
              readOnly :false,
              folder:props.folder,
              payslipData:data
            }
          }
          context.emit('add-tab', tabsSpec)
        }
        else if (props.folder.name === 'leaves') {
          const data = JSON.parse(JSON.stringify(params.data))
          showCalendarView(data)
        }
        else {
          const currentTxn = getCurrentTxn(currentTaskName.value, props.folder)
          txnToUse.value = currentTxn
          const txnLabel = txnToUse.value?.label?.eng
          const tabsSpec = {
            label: txnLabel, //txnToUse.label.eng, //passing the current Txns label so that buttons can be used as save btn
            component: 'FolderForm',
            name:`update ${nameToAdd}`,
            params: formBaseParams.value
          }
          context.emit('add-tab', tabsSpec)
        }

      }
      else {
        if (props.folder.name === 'payslips') {
          const data = params.data
          const employeeName = data?.employees_id?.[0]?.persons_id?.[0].first_name?.eng
          const payrollMonth = data?.payroll_month
          const monthOfSlip = new Date(payrollMonth).toLocaleString('default', { month: 'short' });
          const yearOfSlip = new Date(payrollMonth).getFullYear()
          const tabsSpec = {
            label: employeeName,
            component:'PayslipsTemplate',
            name:`${employeeName} ${monthOfSlip}/${yearOfSlip}`,
            params:{
              readOnly :false,
              folder:props.folder,
              payslipData:data
            }
          }
          context.emit('add-tab', tabsSpec)
        }
      }
    }
    //add new Entry
    function createNewEntry(txn) {
      txnToUse.value = txn
      const fieldsCopy = JSON.parse(JSON.stringify(props.folder.fields))
      const {getNullObject} = getUtility()
      // currentRowData.value = getNullObject(fieldsCopy)
      currentRowData.value = getNullObject(fieldsCopy)
      currentTaskName.value = 'create'
      const txnLabel = txnToUse.value?.label?.eng

      const tabsSpec = {
        label: txnLabel,
        component: 'FolderForm',
        name:txnToUse.value.label.eng,
        params: formBaseParams.value,
        readOnly:false
      }

      context.emit('add-tab', tabsSpec)

    }
    function uploadFile(txn) {
      currentRowData.value = {};
      currentTaskName.value = txn.label.eng
      bulkUploadForm.value = true;
      txnToUse.value = txn
    }
    //delete entries
    //TODO:-implement when done from backend
    async function removeRows() {
      const selectedRows = gridApi.value.getSelectedRows()
      if (selectedRows.length > 0) {
        currentRowNode.value = gridApi.value.getSelectedNodes()

        //start a notification of task progress  with specific id
        const dataToDelete = []
        const txnType = 'delete'
        for (var i = 0; i < selectedRows.length; i++) {
          dataToDelete.push({
            id: selectedRows[i].id,
            deleted: true
          })
        // await deleteEntry(formData, selectedRows[i])
        }
        await deleteEntry(dataToDelete, txnType)
      }
      else {
        toast.warning('please select one or more records')
      }
    }
    function listRefreshTrigger() {
      refreshList.value = !refreshList.value;
    }
    async function handleResult(data) {
      searchLoading.value = false
      if (data.output.type === 'success') {
        const { fatRowGenerator } = getFatRow()
        const records = [];
        for (let i = 0; i < data.output.data.records.length; i++) {
          records.push(JSON.parse(data.output.data.records[i]))
        }
        data.output.data.records = records;
        //make the grid update without refresh
        const listData = fatRowGenerator(data)
        //Depending on the rowModel change this
        if (isBulkTxn.value) {
          for (var i = 0; i < currentRowNode.value.length; i++) {
            currentRowNode.value[i]?.setData(listData[i])
          }
        }
        else if (listData[0] && !deleteTxn.value) {
          currentRowNode.value[0]?.setData(listData[0])
        } else {
          const selectedRow = gridApi.value.getSelectedRows()
          gridApi.value?.applyTransaction({
            remove: selectedRow
          })
        }
        if (data.output.type === 'success')
        {
          toast.update(notificationId.value, {
            render: 'done',
            autoClose: 1000,
            closeButton: true,
            type: 'success',
            isLoading: false
          })
          // deleteRecord.value = false
        }
      }
      if (data.output.type === 'error') {
        // if (deleteTxn.value) {
        //   deleteRecord.value = true
        // }
        toast.update(notificationId.value, {
          render: `Error !${data.output.message}`,
          closeButton: true,
          type: 'error',
          autoClose: 6000,
          isLoading: false
        })
      }
      //empty the arrays that is returned to make the txn after its used to avoid being used again in another txn
      listRefreshTrigger()
      emptyTheContainer()
    }

    //on clicking deleteEntry run the transaction
    async function deleteEntry(data, txnType) {
      try {
        //if no data is passed .. get it from formData
        // const formData = data ? data : store.getters["commonModule/formData"]
        //TODO-check for errors
        const formData = data
        notificationId.value = toast.loading('working on it...', {
          position: toast.POSITION.TOP_CENTER
        })
        const params = {
          normalFieldList:formData,
          folderDetails :props.folder,
          currentTaskName: currentTaskName.value,
          txnType :txnType,
          currentFolderId:mainFolderId.value
        }
        //generate the txnToRun with txn and fieldsData
        const { generateTxn } = makeTxn(params)

        const txnToRun = generateTxn()
        txnToRun.session_key = sessionId;
        if (forceDelete.value) {
          txnToRun.deleteDependentData = true
        }
        list.value = await sendMessage(txnToRun)
        forceDelete.value = false
        txnToRun.deleteDependentData = false
        deleteTxn.value = true
        await handleResult(list.value)
      } catch (err) {
        throw err
      }
    }
    function refByName(refBy) {
      const result = `${refBy.tablename}`
      return result
    }
    // function refByLabel(refBy) {
    //   //remove the docp part from name
    //   // const arrayOfRefByName = refBy.key.split('docp')
    //   // console.log(arrayOfRefByName)
    //   // const useful = refBy.key.split('docp').slice(0, -1)
    //   // const derivedLabel = useful[0].split('_').join(' ')
    //   const cleanedStrings = cleanString(refBy)
    //   return cleanedStrings
    // }
    // function cleanString(input) {
    //   const detailRemovedString = input.key.replace(/_detail_/g, '_')
    //   const stringAry = detailRemovedString.split('_docp_')
    //   if (stringAry.length == 1) {
    //     return stringAry[0]
    //   }
    //   else
    //   {
    //     if (stringAry.at(-1) == `${props.folder.name}_id`) {
    //       //if string that comes after docp is foldername_id remove that
    //       stringAry.pop()
    //     }
    //     if (stringAry.at(-1) == 'designation_id') {
    //       stringAry.pop()
    //     }
    //     if (stringAry.length > 1 && stringAry[0] == input.tablename) {
    //       //if the first part is same as tablename remove
    //       stringAry.splice(0, 1)
    //     }
    //   }
    //   const joinedString = stringAry.join('_')
    //   const finalArray = joinedString.split('_')

    //   if (finalArray.length > 1 && finalArray[0] == input.tablename) {
    //     finalArray.splice(0, 1)
    //   }
    //   const finalString = finalArray.join(' ')
    //   return finalString

    // }


    function refByToShow() {
      const result = {}
      const fname = `business.${props.folder.foldertype}.${props.folder.name}`
      const visibleFolderList = getFoldersList(bSettings)
      // we only bother with refBy of business folders, not globals or directory
      const refByMap = bSettings.output.data.records[0].referencedByMap[fname]
      if (refByMap != null) {
        //we are using the id of the rowData to filter exact
        for (const key in refByMap) {
          const refBy = refByMap[key]
          refBy.key = key
          refBy.name = refByName(refBy)
          // refBy.label = refByLabel(refBy)
          refBy.loading = false
          const tableName = refBy.tablename
          //checking if the ref should be pushed or not
          if (!refBy.hideRef) {
            // avoid self-refs and deduplicate
            // && !result.find((x) => x.label === refBy.tablename)
            if (refBy.tablename != props.folder.name) {
            //ensure folder is in visibleFolderList (visible)
              if (visibleFolderList.find((x) => x.name === refBy.tablename)) {
                //if same table name comes up again make and array
                if (!result.hasOwnProperty(tableName)) {
                  result[tableName] = []
                  result[tableName].push(refBy)

                }
                else {
                  //else keep adding key value pair
                  result[tableName].push(refBy)
                }

              }
            }
          }
        }
      }
      return result
    }

    return {
      performAction,
      handleRowClick,
      currentTaskName,
      currentRowNode,
      actionsToPerform,
      searchLoading,
      deleteRecord,
      //cancelDelete,
      //dependencyDelete,
      removeFromSelected,
      bulkUploadForm,
      txnToUse,
      gridApi,
      handleSearch,
      searchTermToPass,
      folderName,
      closeUpload,
      listSearchTestId,
      showInModal,
      getGridApi,
      filterIcon,
      showFilters,
      toggleFilterView,
      applyDateFilters,
      fieldsToFilter,
      showCalendarView,
      openAdminCalendar,
      refreshList,
      listRefreshTrigger,
      currentRowData
    }
  }
}
</script>

<style lang="css">
.list-search-class {
  min-width: 20px !important;
  color: var(--main-color) !important;
  background: var(--search-box-background) !important;
  border: black
}

</style>
<style scoped>
.toolbar-class {
  color: white;
  background-color: var(--toolbar-color);
  padding-top:30px;
  padding-bottom:30px;
  padding-right:15px;
}

.msg-body{
  padding:10px;
  height: fit-content;
  max-height:400px;
  overflow-y: scroll;
  font-size: 1.1rem;
  text-align: left;
}

.delete-list{
border:1px solid grey;
border-radius: 8px;
}

.save-btn {
  border: 2px solid #2e688dff;
  background-color: white;
  color:#2e688dff;
  border-radius: 6px;
  font-size:18px;
  padding-left:8px;
  font-weight: 600;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding-right:8px;

}
.save-btn:hover {
  background-color: #2e688dff;
  color: white;
}

</style>
