<template>
  <div class="d-flex justify-space-between footer-content">
    <div class="d-flex">
      <div v-if="leavesQuota?.opening_balance != null && leavesQuota?.multiCTCheck != 1" class="card p-3 ms-2">
        Prev. Balance : {{ leavesQuota?.opening_balance }}
      </div>
      <div v-if="leavesQuota?.credited != null && leavesQuota?.multiCTCheck != 1" class="card p-3 ms-2">
        Credit this month : {{ leavesQuota?.credited }}
      </div>
      <div v-if="leavesQuota?.multiCTCheck != 1" class="card p-3 ms-2">
        This month's Leaves Taken : {{ leavesQuota?.monthly_leaves_taken }}
      </div>
      <div v-if="leavesQuota?.multiCTCheck != 1" class="card p-3 ms-2">
        This month's Leaves Left : {{ leavesQuota?.leaves_left }}
      </div>
      <div v-if="leavesQuota?.multiCTCheck != 1" class="card p-3 ms-2">
        Total Leaves Taken : {{ leavesQuota?.leaves_used }}
      </div>
      <div v-if="leavesQuota?.multiCTCheck != 1" class="card p-3 ms-2">
        Yearly Leaves Left : {{ leavesQuota?.yearly_leaves_left }} of {{ leavesQuota?.total_yearly_leaves }}
      </div>
      <div v-if="leavesQuota?.multiCTCheck != 1" class="card p-3 ms-2">
        Extra : {{ leavesQuota?.extra }}
      </div>
    </div>
    <div
      v-if="!multiSelect"
      class="d-flex"
    >
      <div class="my-auto">
        <div class="bg-success status-dot" />
        Final Approved
      </div>
      <div class="my-auto ms-1">
        <div class="bg-warning status-dot" />
        Cancelled
      </div>
      <div class="my-auto ms-1">
        <div class="bg-primary status-dot" />
        Intermediate Approved
      </div>
      <div class="my-auto ms-1">
        <div class="bg-light status-dot border" />
        Created
      </div>
      <div class="my-auto ms-1 me-1">
        <div class="bg-danger status-dot" />
        Rejected
      </div>
    </div>
    <div
      v-else
    >
      Selected Dates
      <div class="days-div row  my-auto">
        <div
          v-for="(item,index) in selectedEvents"
          :key="index"
          class="ms-2 mt-1 each-date my-auto col-sm-2"
        >
          <div class="my-auto">
            {{ getDate(item) }}
          </div>
        </div>
      </div>
    </div>
    <div class="my-auto  leaves-footer-text me-3">
      <div>
        <b>Help Text</b>
      </div>
      <v-tooltip
        activator="parent"
        open-delay="300"
        location="top"
      >
        <div>
          <em>*Use <b>Ctrl</b> key To Select Multiple Events</em>
        </div>
        <div><em>*Use <b>Click + Drag</b>To Select Multiple Dates</em></div>
      </v-tooltip>
    </div>
  </div>
</template>
<script>
import {computed} from 'vue'
export default {
  name:'LeavesFooter',
  props:{
    leavesQuota:{
      type:Object,
      default:null
    },
    multiSelect:{
      type:Boolean,
      default:null
    },
    selectedEvents:{
      type:Array,
      default:null
    },
    dateToShow:{
      type:String,
      default:null
    }
  },
  setup(props) {

    const monthToShow = computed(() => {
      const currentViewDate = new Date(props.dateToShow)
      const today = new Date()
      const firstDateOfThisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      const firstDateOfCurrentView = new Date(currentViewDate.getFullYear(), currentViewDate.getMonth(), 1)
      if (firstDateOfCurrentView < firstDateOfThisMonth) {
        return firstDateOfThisMonth.toLocaleString('default', {month:'long'})
      }
      else {
        return firstDateOfCurrentView.toLocaleString('default', {month:'long'})
      }
    })
    function getDate(arg) {
      const startDate = arg?.event?._instance?.range?.start
      const month = startDate?.toLocaleString('default', { month: 'short' });

      return `${startDate.getDate()} ${month}`
    }

    return {
      getDate,
      monthToShow
    }
  }
}
</script>

<style scoped>
.each-date{
  width: 40px;
  height: 20px;
  border:1px solid green;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 600;
  padding:3px;
}
.leaves-footer-text{
    font-size: 13px;
}
.leaves-footer-text b{
    cursor: pointer;
    font-size: 15px;
}
.footer-content{
  background-color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 8px;
}
.card{
color:#6faa5dff;
font-weight: 700;
border-radius: 8px;

}

.status-dot{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.3s;
}
</style>
