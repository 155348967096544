import processExpr from './processExprs';
import postData from './postData'
import getFatRow from './fatRowGenerator';
import getProjections from './getProjections';
const processRowData = () => {
  function getProcessedDataList(folder, rowData, fieldsToShow) {
    if (Array.isArray(fieldsToShow)) {
      const computedFields = fieldsToShow.filter((each) => each.type == 'cfield')
      if (rowData && rowData?.length != 0) {
        rowData.forEach((eachRow) => {
          for (var i = 0; i < computedFields.length; i++) {
            const cExpr = computedFields[i].computed_expr
            const fieldName = computedFields[i].name
            const {computedExpr} = processExpr(folder, eachRow)
            let retval = computedExpr(cExpr)
            if (typeof retval == 'number') {
              retval = retval.toFixed(2)
            }
            eachRow[fieldName] = retval
          }
        });
      }

    }
    return rowData
  }
  async function getProcessedEmployeeListData(empFolder, rowData) {
    const {projectionForFolder} = getProjections()
    const retVal = []
    //get unique officer ids for filter puroose
    rowData?.forEach(({ subsections }) => {
      if (subsections?.[0]) {
        ['approving_officer', 'reporting_officer', 'duty_chart_officer'].forEach((each) => {
          const officerId = subsections[0]?.[each]?.[0]?.persons_id?.[0];
          if (officerId) {
            if (!retVal.includes(officerId)) {
              retVal.push(officerId);
            }
          }
        });
      }
    });
    console.log('officerId', retVal)

    // Send employee list with filter
    const txnParams = {}
    txnParams.projections = projectionForFolder('employees_list_for_persons');
    txnParams.filters = {
      joinop: 'or',
      filterInput: retVal.map((id) => ({
        operator: '=',
        path: 'employees.persons_id.id',
        value: id,
      })),
    };

    const { listData } = postData();
    const filterListTxn = await listData(empFolder, txnParams);
    const { fatRowGenerator } = getFatRow()
    const filterListData = fatRowGenerator(filterListTxn);

    // Compare and update row data with fetched employee details
    rowData?.forEach(({ subsections }) => {
      if (subsections?.[0]) {
        filterListData.forEach(({ persons_id }) => {
          if (persons_id?.[0]?.id) {
            ['approving_officer', 'reporting_officer', 'duty_chart_officer'].forEach((role) => {
              if (subsections[0]?.[role]?.[0]?.persons_id?.[0] === persons_id[0].id) {
                subsections[0][role][0].persons_id = persons_id;
              }
            });
          }
        });
      }
    });
    return rowData
  }
  return { getProcessedDataList, getProcessedEmployeeListData};
};

export default processRowData;
