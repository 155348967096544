import store from '../../store';
import { sendMessage } from '../services/websocket';
import { toast } from 'vue3-toastify';
import processFormData from './processFormData';
import makeTxn from './makeTxn';
import foldersUtility from './foldersUtility';
import makeFilter from './makeFilter';
import postData from './postData';
import getFatRow from './fatRowGenerator';
import makeTxnForBulk from './makeTxnForBulk';
import getFolders from './getFolders';
import getConverters from './getConverters';
import { v4 as uuidv4 } from 'uuid';

const attendanceGridUtils = () => {

  function createTimeRange(monthYear) {
    const month = monthYear.month;
    const startDay = 1;
    const year = monthYear.year;
    const endDay = new Date(year, month, 0).getDate();

    // Create a Date object with the specified date and time
    const timestamp = new Date(Date.UTC(year, month - 1, startDay, 0, 0, 0));
    const timestamp2 = new Date(Date.UTC(year, month - 1, endDay, 23, 59, 59));
    // Format the timestamp
    const formattedTimestamp = timestamp
      .toISOString()
      .replace('T', ' ')
      .slice(0, 19);
    const inputDate1 = new Date(formattedTimestamp);

    // Subtract 5 hours and 30 minutes
    inputDate1.setHours(inputDate1.getHours() - 5);
    inputDate1.setMinutes(inputDate1.getMinutes() - 30);
    const year1 = inputDate1.getFullYear();
    const month1 = String(inputDate1.getMonth() + 1).padStart(2, '0');
    const day1 = String(inputDate1.getDate()).padStart(2, '0');
    const hours1 = String(inputDate1.getHours()).padStart(2, '0');
    const minutes1 = String(inputDate1.getMinutes()).padStart(2, '0');
    const seconds1 = String(inputDate1.getSeconds()).padStart(2, '0');
    const formattedResult1 = `${year1}-${month1}-${day1} ${hours1}:${minutes1}:${seconds1}`;

    const formattedTimestamp2 = timestamp2
      .toISOString()
      .replace('T', ' ')
      .slice(0, 19);
    const inputDate2 = new Date(formattedTimestamp2);
    // Subtract 5 hours and 30 minutes
    inputDate2.setHours(inputDate2.getHours() - 5);
    inputDate2.setMinutes(inputDate2.getMinutes() - 30);
    const year2 = inputDate2.getFullYear();
    const month2 = String(inputDate2.getMonth() + 1).padStart(2, '0');
    const day2 = String(inputDate2.getDate()).padStart(2, '0');
    const hours2 = String(inputDate2.getHours()).padStart(2, '0');
    const minutes2 = String(inputDate2.getMinutes()).padStart(2, '0');
    const seconds2 = String(inputDate2.getSeconds()).padStart(2, '0');
    const formattedResult2 = `${year2}-${month2}-${day2} ${hours2}:${minutes2}:${seconds2}`;
    return [formattedResult1, formattedResult2];
  }

  function getRandomColor(docpName, code, alpha) {
    if (docpName === 'sites_id') {
      let hash = 0;
      for (let i = 0; i < code.length; i++) {
        hash = code.charCodeAt(i) + ((hash << 2) - hash);
      }

      // Use the hash code to determine the values for H, S, and L.
      const hue = ((hash % 360) + 360) % 360; // Ensure hue is between 0 and 359
      const saturation = 30 + (70 * (hash % 101)) / 100; // Set saturation between 25% and 95%
      const lightness = 65 + (25 * (hash % 101)) / 100; // Set lightness between 70% and 95%

      // Return the HSLA string with the specified alpha.
      return (
        'hsla(' +
        hue +
        ', ' +
        saturation +
        '%, ' +
        lightness +
        '%, ' +
        alpha +
        ')'
      );
    } else if (docpName === 'designation') {
      let hash = 0;
      for (let i = 0; i < code.length; i++) {
        hash = code.charCodeAt(i) + ((hash << 5) - hash);
      }

      // Use the hash code to determine the values for H, S, and L.
      const hue = ((hash % 360) + 360) % 360; // Ensure hue is between 0 and 359
      const saturation = 30 + (60 * (hash % 101)) / 100; // Set saturation between 25% and 95%
      const lightness = 67 + (10 * (hash % 101)) / 100; // Set lightness between 60% and 70%

      // Return the HSLA string with the specified alpha.
      return (
        'hsla(' +
        hue +
        ', ' +
        saturation +
        '%, ' +
        lightness +
        '%, ' +
        alpha +
        ')'
      );
    }
    return null;
  }

  function quickSort(array, sortBy) {
    if (array.length <= 1) {
      return array;
    }

    const pivotIndex = Math.floor(array.length / 2);
    const pivot = array.splice(pivotIndex, 1)[0];
    const left = [];
    const right = [];

    for (let i = 0; i < array.length; i++) {
      let comparison;
      if (sortBy === 'code') {
        comparison = array?.[i]?.['employees']?.[0]?.['code']
          .trim()
          .localeCompare(pivot['employees']?.[0]?.['code']?.trim());
      } else {
        comparison = array?.[i]?.['employees']?.[0]?.['persons_id']?.[0]?.['first_name']?.eng
          .trim()
          .localeCompare(
            pivot['employees']?.[0]?.['persons_id']?.[0]?.['first_name']?.eng?.trim()
          );
      }
      if (comparison === -1) {
        left.push(array[i]);
      } else {
        right.push(array[i]);
      }
    }

    return [...quickSort(left, sortBy), pivot, ...quickSort(right, sortBy)];
  }

  function getDisplayStringForHrs(hrs) {
    let result = '';
    hrs = Number(hrs);
    if (!isNaN(hrs)) {
      const hrsFloor = Math.floor(hrs);
      if (hrsFloor.toString().length < 2) {
        result += '0';
      }
      result += hrsFloor.toString() + ':';
      const decimal = hrs - hrsFloor;
      let mins = (decimal / 100) * 60;
      mins = mins.toString().split('.')[1] || '0';
      if (mins.toString().length < 2) {
        result += mins + '0';
      } else {
        let x = Number(mins.substr(0, 2) + '.' + mins.substr(2));
        x = Math.round(x);
        result += x.toString().length < 2 ? '0' + x : x;
      }
    } else {
      result = '00:00';
    }
    return result;
  }

  function formatHrsAndDays(val) {
    const retval = [];
    let result;
    if (val != null) {
      if (val.total_payable_days > 0) {
        result = '';
        result = result + 'TPD:' + val.total_payable_days;
        retval.push(result);
      }
      if (val.present > 0) {
        result = '';
        result = result + 'P:' + val.present;
        retval.push(result);
      }
      if (val.punchRequest > 0) {
        result = '';
        result = result + 'PR:' + val.punchRequest;
        retval.push(result);
      }
      if (val.partialAttendance > 0) {
        result = '';
        result = result + 'SP:' + val.partialAttendance;
        retval.push(result);
      }
      if (val.absent > 0) {
        result = '';
        result = result + 'A:' + val.absent;
        retval.push(result);
      }
      if (val.rest > 0) {
        result = '';
        result = result + 'R:' + val.rest;
        retval.push(result);
      }
      if (val.weeklyOff > 0) {
        result = '';
        result = result + 'WO:' + val.weeklyOff;
        retval.push(result);
      }
      if (val.tour > 0) {
        result = '';
        result = result + 'T:' + val.tour;
        retval.push(result);
      }
      if (val.holidays > 0) {
        result = '';
        result = result + 'H:' + val.holidays;
        retval.push(result);
      }
      if (val.leaves > 0) {
        result = '';
        result = result + 'L:' + val.leaves;
        retval.push(result);
      }
      if (val.leaveWithoutPay > 0) {
        result = '';
        result = result + 'LWP:' + val.leaveWithoutPay;
        retval.push(result);
      }
      if ('deficitHours' in val) {
        result = '';
        result = `${result} DH: (${deficitMinutesToHours(val.deficitHours, false)})`;
        retval.push(result);
      }
    }
    return retval;
  }

  function convertMinutesToHours(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const decimalHours = remainingMinutes / 60;
    const totalHours = hours + decimalHours;
    return totalHours;
  }

  function getTotalDays(data) {
    const totalHrsAndDays = {
      present: 0,
      absent: 0,
      partialAttendance: 0,
      punchRequest: 0,
      rest: 0,
      weeklyOff: 0,
      holidays: 0,
      leaves: 0,
      leaveWithoutPay: 0,
      deficitHours: 0,
      tour: 0,
      total_payable_days: 0
    };
    for (const prop in data) {
      if (prop !== 'employees') {
        if ('att_type' in data[prop]) {
          if (data[prop].att_type[0].code === 'P') {
            totalHrsAndDays.present += 1;
            totalHrsAndDays.total_payable_days += 1;
          } else if (data[prop].att_type[0].code === 'SP') {
            totalHrsAndDays.partialAttendance += 1;
          } else if (data[prop].att_type[0].code === 'PR') {
            totalHrsAndDays.punchRequest += 1;
          } else if (data[prop].att_type[0].code === 'A') {
            totalHrsAndDays.absent += 1;
          } else if (data[prop].att_type[0].code === 'T') {
            totalHrsAndDays.total_payable_days += 1;
            totalHrsAndDays.tour += 1;
          } else if (data[prop].att_type[0].code === 'R') {
            totalHrsAndDays.total_payable_days += 1;
            totalHrsAndDays.rest += 1;
          } else if (data[prop].att_type[0].code === 'WO') {
            totalHrsAndDays.total_payable_days += 1;
            totalHrsAndDays.weeklyOff += 1;
          } else if (data[prop].att_type[0].code === 'H') {
            totalHrsAndDays.total_payable_days += 1;
            totalHrsAndDays.holidays += 1;
          } else if (data[prop].att_type[0].code === 'LWP') {
            totalHrsAndDays.leaveWithoutPay += 1;
          } else if (data[prop].att_type[0].code === 'HD') {
            if (data[prop]?.leave_type?.length > 0) {
              totalHrsAndDays.leaves += 0.5;
              totalHrsAndDays.present += 0.5;
              totalHrsAndDays.total_payable_days += 1;
            } else {
              totalHrsAndDays.present += 0.5;
              totalHrsAndDays.total_payable_days += 0.5;
            }
          } else if (data[prop].att_type[0].code === 'L') {
            if (data[prop]?.leave_type?.length > 0 && data[prop].leave_type?.[0]?.code === 'WO') {
              totalHrsAndDays.weeklyOff += 1;
              totalHrsAndDays.total_payable_days += 1;
            } else if (data[prop]?.leave_type?.length > 0 && data[prop].leave_type?.[0]?.code === 'LWP') {
              totalHrsAndDays.leaveWithoutPay += 1;
            } else {
              totalHrsAndDays.leaves += 1;
              totalHrsAndDays.total_payable_days += 1;
            }
          }
        }
        if ('deficit_minutes' in data[prop] && data[prop].deficit_minutes !== null) {
          if (data[prop].deficit_minutes < 0) {
            totalHrsAndDays.deficitHours += data[prop].deficit_minutes;
          }
        }
      }
    }
    return totalHrsAndDays;
  }

  function formatDays(currentDate, month, year, weekRange) {
    const monthAbbreviations = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const daysOfWeekAbbreviations = [
      'Sun',
      'Mon',
      'Tue',
      'Wed',
      'Thu',
      'Fri',
      'Sat',
    ];
    let lastDayOfMonth = null;
    const formattedDays = [];
    if (currentDate !== null && currentDate !== undefined && currentDate !== 1) {
      let prevYear = null;
      if (month === 1) {
        prevYear = year - 1;
      }
      let currentMonth = month - 1;
      lastDayOfMonth = new Date(prevYear !== null ? prevYear : year, currentMonth, 0).getDate();
      for (let day = currentDate; day <= lastDayOfMonth; day++) {
        currentMonth = month - 2;
        const currentDay = new Date(prevYear !== null ? prevYear : year, currentMonth !== -1 ? currentMonth : 11, day);
        const dayOfWeekAbbreviation =
          daysOfWeekAbbreviations[currentDay.getDay()];
        formattedDays.push({
          day: day,
          formattedDay: `${dayOfWeekAbbreviation} ${day}`,
          month: monthAbbreviations[currentMonth],
          year: prevYear !== null ? prevYear : year
        });
      }
      for (let day = 1; day <= currentDate - 1; day++) {
        currentMonth = month - 1;
        const currentDay = new Date(year, currentMonth, day);
        const dayOfWeekAbbreviation =
          daysOfWeekAbbreviations[currentDay.getDay()];
        formattedDays.push({
          day: day,
          formattedDay: `${dayOfWeekAbbreviation} ${day}`,
          month: monthAbbreviations[currentMonth],
          year: year
        });
      }
    } else if (currentDate !== null) {
      lastDayOfMonth = new Date(year, month, 0).getDate();
      for (let day = 1; day <= lastDayOfMonth; day++) {
        const currentDay = new Date(year, month - 1, day);
        const dayOfWeekAbbreviation =
          daysOfWeekAbbreviations[currentDay.getDay()];
        formattedDays.push({
          day: day,
          formattedDay: `${dayOfWeekAbbreviation} ${day}`,
          month: monthAbbreviations[month - 1],
          year: year
        });
      }
    } else {
      const startDay = weekRange.startOfWeek;
      const startMonth = weekRange.startMonth;
      const startYear = weekRange.startYear;
      const endDay = weekRange.endOfWeek;
      const endMonth = weekRange.endMonth;
      const endYear = weekRange.endYear;
      if (startMonth === endMonth) {
        lastDayOfMonth = endDay;
        for (let day = startDay; day <= lastDayOfMonth; day++) {
          const currentDay = new Date(startYear, startMonth, day);
          const dayOfWeekAbbreviation =
            daysOfWeekAbbreviations[currentDay.getDay()];
          formattedDays.push({
            day: day,
            formattedDay: `${dayOfWeekAbbreviation} ${day}`,
            month: monthAbbreviations[startMonth],
            year: startYear
          });
        }
      } else {
        lastDayOfMonth = new Date(startYear, startMonth + 1, 0).getDate()
        for (let day = startDay; day <= lastDayOfMonth; day++) {
          const currentDay = new Date(startYear, startMonth, day);
          const dayOfWeekAbbreviation =
            daysOfWeekAbbreviations[currentDay.getDay()];
          formattedDays.push({
            day: day,
            formattedDay: `${dayOfWeekAbbreviation} ${day}`,
            month: monthAbbreviations[startMonth],
            year: startYear
          });
        }
        lastDayOfMonth = endDay
        for (let day = 1; day <= lastDayOfMonth; day++) {
          const currentDay = new Date(endYear, endMonth, day);
          const dayOfWeekAbbreviation =
            daysOfWeekAbbreviations[currentDay.getDay()];
          formattedDays.push({
            day: day,
            formattedDay: `${dayOfWeekAbbreviation} ${day}`,
            month: monthAbbreviations[endMonth],
            year: endYear
          });
        }
      }
    }
    return formattedDays;
  }

  function convertUtcToIst(utcTimestamp) {
    if (utcTimestamp == null) {
      return '0:0:0';
    }
    // Convert the UTC timestamp to a Date object
    const utcDate = new Date(utcTimestamp);
    const ISTOffset = 330; // IST is UTC+5:30, so 330 minutes

    // Get the current timezone offset of the local system in minutes
    const localOffset = utcDate.getTimezoneOffset(); // Local system timezone offset in minutes

    // Calculate the time difference between UTC and IST
    const offsetDifference = ISTOffset - localOffset;
    // Convert the UTC date to IST by adding the offset
    const istDate = new Date(utcDate.getTime() + offsetDifference);
    // Extract hours, minutes, seconds
    let hours = istDate.getHours();  // Use getUTCHours to avoid local time zone interference
    const minutes = istDate.getMinutes();
    const seconds = istDate.getSeconds();
    // Determine AM or PM
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${ampm}`;
    return formattedTime;
  }

  function convertMinutesToHoursFormat(minutes) {
    if (minutes === null || minutes === undefined || isNaN(minutes)) {
      return '00:00'
    }
    const hours = Math.floor(minutes / 60).toString().padStart(2, '0');
    const remainingMinutes = (minutes % 60).toString().padStart(2, '0');
    return `${hours}:${remainingMinutes}`;
  }

  function timeStringToMinutes(timeString) {
    if (timeString === '' || timeString === null || timeString === undefined || timeString === '0') {
      return 0;
    }
    var timeArray = timeString.split(':');
    var hours = parseInt(timeArray[0]);
    var minutes = parseInt(timeArray[1]);
    return hours * 60 + minutes;
  }

  function convertUTCToISTDateTime(utcDateString) {
    const utcDate = new Date(utcDateString);
    const ISTOffset = 330; // IST is UTC+5:30, so 330 minutes
    const localOffset = utcDate.getTimezoneOffset(); // Local system timezone offset in minutes
    const offsetDifference = ISTOffset - localOffset;
    const istDate = new Date(utcDate.getTime() + offsetDifference);
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const day = istDate.getDate();
    const month = monthNames[istDate.getMonth()];
    const year = istDate.getFullYear();
    let hours = istDate.getHours();
    const minutes = istDate.getMinutes().toString().padStart(2, '0');
    const seconds = istDate.getSeconds().toString().padStart(2, '0');
    const period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${period}`;
    return formattedDateTime;
  }

  function convertToUTCTimestamp(timeString, dateString) {
    // Handle special cases: "0:0:0", null, or empty string
    if (timeString === '0:0:0' || timeString === null || timeString === '') {
      return null;
    }

    // Parse the time string "HH:MM:SS"
    const [hours, minutes, seconds] = timeString.split(':').map(Number);

    // Parse the date string "1Sep2024"
    const dateParts = dateString.split('/');
    if (dateParts.length !== 3) {
      throw new Error('Invalid date format');
    }

    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Month is 0-indexed in JavaScript Date
    const year = parseInt(dateParts[2], 10);
    // Create a Date object in IST using the parsed date and time
    const istDate = new Date(Date.UTC(year, month, day, hours, minutes, seconds));

    // Subtract 5 hours and 30 minutes from IST to get UTC
    const timeZoneOffset = istDate.getTimezoneOffset();
    if (timeZoneOffset > 0) {
      istDate.setUTCMinutes(istDate.getUTCMinutes() - timeZoneOffset);
    } else {
      istDate.setUTCMinutes(istDate.getUTCMinutes() - (-timeZoneOffset));
    }
    // 5 hours and 30 minutes is 330 minutes

    // Return the UTC timestamp in ISO format
    return istDate.toISOString();
  }

  function removeUserRemark(text) {
    if (text != undefined && text.includes('_USERREMARK_')) {
      const modifiedText = text.replace(/_USERREMARK_/g, '');
      return { modifiedText: modifiedText, found: true };
    } else {
      return { modifiedText: text, found: false };
    }
  }

  function deficitMinutesToHours(minutes, isHMFormat) {
    if (minutes === null || minutes === undefined || isNaN(minutes)) {
      return '00:00'
    }
    if (minutes < 0) {
      minutes = -(minutes);
      const hours = Math.floor(minutes / 60).toString().padStart(2, '0');
      const remainingMinutes = (minutes % 60).toString().padStart(2, '0');
      if (isHMFormat) {
        return  `${hours}h : ${remainingMinutes}m`;
      }
      return `-${hours}:${remainingMinutes}`;
    }
    const hours = Math.floor(minutes / 60).toString().padStart(2, '0');
    const remainingMinutes = (minutes % 60).toString().padStart(2, '0');
    if (isHMFormat) {
      return  `${hours}h : ${remainingMinutes}m`;
    }
    return `${hours}:${remainingMinutes}`;
  }

  const setEditableFields = (columnsFields, editsForm) => {
    if (editsForm === null || editsForm === undefined) {
      return columnsFields;
    }
    return columnsFields.map((column) => {
      const matchingField = editsForm.find((field) => field.path.split('.').pop() === column.fieldName);

      if (matchingField && matchingField.readonly_expr === 'true') {
        return {
          ...column,
          editable: false
        };
      }
      if (matchingField && matchingField.readonly_expr === 'false') {
        return {
          ...column,
          editable: typeof column.editable === 'function' ? column.editable : true
        };
      }
      return column;
    });
  };

  function attendanceRegisterColumns(gridDataSource) {
    const bSettings = store.getters['bSettings'];
    const { getAllFoldersList, getCurrentFolder } = getFolders()
    const folderList = getAllFoldersList(bSettings);
    const attendanceEditsFolder = getCurrentFolder('attendance_edits', folderList);
    const editsForm = attendanceEditsFolder?.forms?.attendance_edits_create_form;
    const columnsFields = [
      {
        headerName: 'Dates',
        fieldName: 'attendance_date',
        maxWidth: '100',
        editable: false,
        valueGetter: (params) => {
          if (params.data !== undefined) {
            return params.data.dates;
          }
        }
      },
      {
        headerName: 'Department',
        fieldName: 'emp_department',
        maxWidth: '300',
        editable: false,
        valueGetter: (params) => {
          if (params.data?.attendanceData?.employees_id[0]?.departments?.length > 0) {
            return params.data.attendanceData.employees_id[0].departments[0].name;
          }
        }
      },
      {
        headerName: 'Subsection',
        fieldName: 'emp_subsection',
        maxWidth: '300',
        editable: false,
        valueGetter: (params) => {
          if (params.data?.attendanceData?.employees_id[0]?.subsections?.length > 0) {
            return params.data.attendanceData.employees_id[0].subsections[0].name;
          }
        }
      },
      /*{
        headerName: 'Sites',
        fieldName: 'sites_id',
        maxWidth: '100',
        editable: (params) => {
          if (params.data !== undefined && params.data !== null && params.data.attendance_status !== undefined && params.data.attendance_status !== null) {
            if (params.data.attendance_status?.[0]?.code === 'final_approved' || params.data.attendance_status?.[0]?.code === 'intermediate_approved') {
              toast.warning('Approved attendance cannot be edited', {
                position: toast.POSITION.TOP_CENTER,
              });
              return false;
            }
          }
          if (params.data !== undefined && params.data !== null && params.data.attendanceData !== undefined && params.data.attendanceData !== null && params.data.attendanceData.leave_type !== undefined && params.data.attendanceData.leave_type !== null && params.data.attendanceData.leave_type.length > 0) {
            toast.warning('Leaves cannot be edited', {
              position: toast.POSITION.TOP_CENTER,
            });
            return false;
          }
          return true;
        },
        cellEditorParams: {'dataSource' :gridDataSource, key : 'sites_id'},
        cellEditor: 'DropdownCellEditor',
        valueGetter: (params) => {
          if (params.data !== undefined) {
            if (params.data.sites_id !== null) {
              if (params.data.sites_id?.[0]?.code === undefined) {
                return params.data.sites_id?.[0]?.code;
              }
              return params.data.sites_id?.[0]?.code;
            }
          }
        },
        valueSetter: (params) => {
          if (params.data.attendance_edit !== undefined) {
            params.data['attendance_edit']['sites_id'] = [params.newValue];
          } else {
            params.data['attendance_edit'] = {};
            params.data['attendance_edit']['sites_id'] = [params.newValue];
          }
          params.data['sites_id'] = [params.newValue];
          return true
        }
      },*/
      {
        headerName: 'In Time',
        fieldName: 'observed_in_tmstmp',
        maxWidth: '150',
        editable: (params) => {
          return false;
        },
        cellEditor: 'TimeCellEditor',
        valueGetter: (params) => {
          if (params.data !== undefined) {
            if (params.data.observed_in_tmstmp !== null) {
              return convertUtcToIst(params.data.observed_in_tmstmp);
            }
          }
        },
        valueSetter: (params) => {
          const inTime = convertToUTCTimestamp(params.newValue, params.data.attendance_date);
          if (params.data.attendance_edit !== undefined) {
            params.data['attendance_edit']['in_timestamp'] = inTime;
          } else {
            params.data['attendance_edit'] = {};
            params.data['attendance_edit']['in_timestamp'] = inTime;
          }
          params.data['observed_in_tmstmp'] = inTime;
          return true;
        }
      },
      {
        headerName: 'Out Time',
        fieldName: 'observed_out_tmstmp',
        maxWidth: '150',
        editable: (params) => {
          return false;
        },
        cellEditor: 'TimeCellEditor',
        valueGetter: (params) => {
          if (params.data !== undefined) {
            if (params.data.observed_out_tmstmp !== null) {
              return convertUtcToIst(params.data.observed_out_tmstmp);
            }
          }
        },
        valueSetter: (params) => {
          const inTime = convertToUTCTimestamp(params.newValue, params.data.attendance_date);
          if (params.data.attendance_edit !== undefined) {
            params.data['attendance_edit']['out_timestamp'] = inTime;
          } else {
            params.data['attendance_edit'] = {};
            params.data['attendance_edit']['out_timestamp'] = inTime;
          }
          params.data['observed_out_tmstmp'] = inTime;
          return true;
        }
      },
      {
        headerName: 'Working Hrs',
        fieldName: 'working_minutes',
        maxWidth: '150',
        editable: (params) => {
          if (params.data !== undefined && params.data !== null && params.data.attendance_status !== undefined && params.data.attendance_status !== null) {
            if (params.data.attendance_status?.[0]?.code === 'final_approved' || params.data.attendance_status?.[0]?.code === 'intermediate_approved') {
              toast.warning('Approved attendance cannot be edited', {
                position: toast.POSITION.TOP_CENTER,
              });
              return false;
            }
          }
          if (params.data !== undefined && params.data !== null && params.data.attendanceData !== undefined && params.data.attendanceData !== null && params.data.attendanceData.leave_type !== undefined && params.data.attendanceData.leave_type !== null && params.data.attendanceData.leave_type.length > 0) {
            toast.warning('Leaves cannot be edited', {
              position: toast.POSITION.TOP_CENTER,
            });
            return false;
          }
          return true;
        },
        cellEditor: 'WorkingTimeEditor',
        valueGetter: (params) => {
          if (params.data !== undefined) {
            if (params.data.working_minutes !== null) {
              return `${convertMinutesToHoursFormat(params.data.working_minutes)}`;
            }
          }
        },
        valueSetter: (params) => {
          const inMinutes = timeStringToMinutes(params.newValue);
          const oldValue = timeStringToMinutes(params.oldValue);
          if (inMinutes > oldValue) {
            const diff = inMinutes - oldValue;
            if (params.data.deficit_minutes === null) {
              params.data.deficit_minutes = -510
            }
            if (params.data.deficit_minutes > 0) {
              params.data.deficit_minutes = diff - params.data.deficit_minutes;
              if (params.data.attendanceData !== undefined && params.data.attendanceData !== null) {
                params.data.attendanceData['deficit_minutes'] = diff - params.data.deficit_minutes;
              }
            } else {
              params.data.deficit_minutes = diff + params.data.deficit_minutes;
              if (params.data.attendanceData !== undefined && params.data.attendanceData !== null) {
                params.data.attendanceData['deficit_minutes'] = diff + params.data.deficit_minutes;
              }
            }
          } else {
            const diff = inMinutes - oldValue;
            if (params.data.deficit_minutes > 0) {
              params.data.deficit_minutes = diff - params.data.deficit_minutes;
              if (params.data.attendanceData !== undefined && params.data.attendanceData !== null) {
                params.data.attendanceData['deficit_minutes'] = diff - params.data.deficit_minutes;
              }
            } else {
              params.data.deficit_minutes = diff + params.data.deficit_minutes;
              if (params.data.attendanceData !== undefined && params.data.attendanceData !== null) {
                params.data.attendanceData['deficit_minutes'] = diff + params.data.deficit_minutes;
              }
            }
          }
          if (params.data.attendance_edit !== undefined) {
            params.data['attendance_edit']['working_minutes'] = inMinutes;
          } else {
            params.data['attendance_edit'] = {};
            params.data['attendance_edit']['working_minutes'] = inMinutes;
          }
          params.data['working_minutes'] = inMinutes;
          return true;
        }
      },
      {
        headerName: 'Difference',
        fieldName: 'deficit_minutes',
        maxWidth: '100',
        editable: false,
        valueGetter: (params) => {
          if (params.data !== undefined) {
            if (params.data.deficit_minutes !== null) {
              if (params.data.deficit_minutes >= 0) {
                return `${deficitMinutesToHours(params.data.deficit_minutes, false)}`;
              }
              else {
                return `(${deficitMinutesToHours(params.data.deficit_minutes, false)})`;
              }
            }
          }
        },
        valueSetter: (params) => {
          return true;
        }
      },
      {
        headerName: 'Attendance Status',
        fieldName: 'att_type',
        maxWidth: '200',
        editable: (params) => {
          if (params.data !== undefined && params.data !== null && params.data.attendance_status !== undefined && params.data.attendance_status !== null) {
            if (params.data.attendance_status?.[0]?.code === 'final_approved' || params.data.attendance_status?.[0]?.code === 'intermediate_approved') {
              toast.warning('Approved attendance cannot be edited', {
                position: toast.POSITION.TOP_CENTER,
              });
              return false;
            }
          }
          // if (params.data !== undefined && params.data !== null && params.data.attendanceData !== undefined && params.data.attendanceData !== null && params.data.attendanceData.leave_type !== undefined && params.data.attendanceData.leave_type !== null && params.data.attendanceData.leave_type.length > 0) {
          //   toast.warning('Leaves cannot be edited', {
          //     position: toast.POSITION.TOP_CENTER,
          //   });
          //   return false;
          // }
          const field = params.colDef.field;
          const data = params.data;
          const bSettings = store.getters['bSettings'];
          const env = bSettings.env;
          if (env?.isHR === true) {
            return true
          }
          if (env?.code !== 'admin' && !env?.isAO && !env?.isRO && !env?.isDCO) {
            if (data[field] !== undefined) {
              if (data.isMissedPunch) {
                return true;
              }
              if (data[field][0].code === 'SP') {
                return true;
              } else {
                toast.warning('Only Single Punch records can be edited', {
                  position: toast.POSITION.TOP_CENTER,
                })
                return false;
              }
            }
          }
          return true;
        },
        cellEditorParams: {'dataSource': gridDataSource, key: 'att_type'},
        cellEditor: 'DropdownCellEditor',
        cellRenderer: 'AttendanceTypeCellRenderer',
        valueGetter: (params) => {
          if (params.data != null) {
            if (params.data.att_type != null) {
              if (params.data.att_type[0]?.code == 'L' && params.data.leave_type != null && params.data.leave_type.length > 0) {
                return params.data.leave_type[0].code;
              }
              else {
                return params.data.att_type[0]?.name?.eng || params.data.att_type[0]?.name;
              }
            }
          }
        },
        valueSetter: (params) => {
          if (params.data.attendance_edit !== undefined) {
            if (Array.isArray(params.newValue)) {
              params.data['attendance_edit']['att_type'] = [params.newValue[0]];
              params.data['attendance_edit']['leave_type'] = [params.newValue[1]];
              params.data['att_type'] = [params.newValue[0]];
              params.data['leave_type'] = [params.newValue[1]];
              if (params.newValue.length === 3) {
                params.data['remarks'] = `_USERREMARK_${params.newValue[2]}`;
                params.data['ilDate'] = `${params.newValue[2]}`
                params.data['attendance_edit']['notes'] =  `_USERREMARK_${params.newValue[2]}`;
              }
            } else {
              params.data['attendance_edit']['att_type'] = [params.newValue];
              params.data['att_type'] = [params.newValue];
            }
          } else {
            params.data['attendance_edit'] = {};
            if (Array.isArray(params.newValue)) {
              params.data['attendance_edit']['att_type'] = [params.newValue[0]];
              params.data['attendance_edit']['leave_type'] = [params.newValue[1]];
              params.data['att_type'] = [params.newValue[0]];
              params.data['leave_type'] = [params.newValue[1]];
              if (params.newValue.length === 3) {
                params.data['remarks'] = `_USERREMARK_${params.newValue[2]}`;
                params.data['ilDate'] = `${params.newValue[2]}`
                params.data['attendance_edit']['notes'] =  `_USERREMARK_${params.newValue[2]}`;
              }
            } else {
              params.data['attendance_edit']['att_type'] = [params.newValue];
              params.data['att_type'] = [params.newValue];
            }
          }
          return true
        }
      },
      {
        headerName: 'Remarks',
        fieldName: 'remarks',
        maxWidth: '470',
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: {
          maxLength: 500, // Max number of characters to allow.
          rows: 1,        // Number of character rows to display.
          cols: 23        // Number of character columns to display.
        },
        editable: (params) => {
          if (params.data !== undefined && params.data !== null && params.data.attendance_status !== undefined && params.data.attendance_status !== null) {
            if (params.data.attendance_status?.[0]?.code === 'final_approved' || params.data.attendance_status?.[0]?.code === 'intermediate_approved') {
              toast.warning('Approved attendance cannot be edited', {
                position: toast.POSITION.TOP_CENTER,
              });
              return false;
            }
          }
          if (params.data !== undefined && params.data !== null && params.data.attendanceData !== undefined && params.data.attendanceData !== null && params.data.attendanceData.leave_type !== undefined && params.data.attendanceData.leave_type !== null && params.data.attendanceData.leave_type.length > 0) {
            toast.warning('Leaves cannot be edited', {
              position: toast.POSITION.TOP_CENTER,
            });
            return false;
          }
          return true;
        },
        valueGetter: (params) => {
          if (params.data !== undefined) {
            if (params.data.remarks != null) {
              if (params.data.att_type?.[0].code == 'SP' || (params.data.attendanceData?.att_type?.[0].code == 'SP' && params.data.remarks.indexOf('by') > -1)) {
                return '';
              }
              const remark = removeUserRemark(params.data.remarks);
              if (remark.found) {
                const remarkDate = new Date(remark.modifiedText)
                if (remarkDate != 'Invalid Date') {
                  return  `In lieu of ${remarkDate.toDateString()}`
                }
                else {
                  const isCo = ['CO'].includes(params.data.attendanceData?.leave_type?.[0]?.code)
                  if (isCo) {
                    return `In lieu of ${remark.modifiedText}`;
                  }
                  else {
                    return `${remark.modifiedText}`;
                  }
                }
              }
              else {
                return params.data.remarks;
              }
            }
          }
        },
        valueSetter: (params) => {
          const oldValue = params.oldValue
          const newValue = params.newValue
          const newStrAdded = newValue.replace(oldValue, '').trim()
          const dateEntered = params.data.ilDate
          const notesToSend = `${dateEntered != null ? dateEntered : ''}_${newStrAdded}`
          if (params.data.attendance_edit !== undefined) {
            params.data['attendance_edit']['notes'] = `_USERREMARK_${notesToSend}`;
          }
          else {
            params.data['attendance_edit'] = {};
            params.data['attendance_edit']['notes'] =  `_USERREMARK_${notesToSend}`;
          }
          params.data['remarks'] =  `_USERREMARK_${newValue}`;
          return true
        }
      },
    ];

    // Call the function to update the editable fields
    const updatedColumnsFields = setEditableFields(columnsFields, editsForm);

    const columnDefs = [];
    for (let i = 0; i < updatedColumnsFields.length; i++) {
      columnDefs.push(
        {
          field: `${updatedColumnsFields[i].fieldName}`,
          sortable: false,
          suppressMovable: true,
          enableCellChangeFlash: true,
          autoHeight: true,
          maxWidth: `${updatedColumnsFields[i].maxWidth}`,
          headerName: `${updatedColumnsFields[i].headerName}`,
          editable: updatedColumnsFields[i].editable,
          cellEditor: updatedColumnsFields[i].cellEditor,
          cellEditorParams: updatedColumnsFields[i].cellEditorParams,
          cellEditorPopup: true,
          cellEditorPopupPosition: 'over',
          onCellValueChanged: registerCellValueChanged(),
          valueGetter: updatedColumnsFields[i].valueGetter,
          valueSetter: updatedColumnsFields[i].valueSetter,
          cellRenderer: updatedColumnsFields[i].cellRenderer,
          cellStyle: (params) => {
            let color = '';
            let fontColor = null;
            let opacity = '';
            let alignment = 'left';
            let paddingLeft = '';
            if (params.data !== undefined && params.data !== null) {
              color = params.data.backgroundColor;
            }
            if (params.colDef.field === 'att_type' && params.data !== undefined && params.data !== null) {
              if (params.data.att_type !== undefined && params.data.att_type !== null) {
                let attCode = params.data.att_type?.[0]?.code;
                if (attCode !== 'HD' && attCode === 'L' && params.data.attendanceData !== undefined && params.value.attendanceData !== null && params.data.attendanceData.leave_type !== undefined && params.data.attendanceData.leave_type !== null && params.data.attendanceData.leave_type.length > 0) {
                  attCode = params.data.attendanceData.leave_type?.[0]?.code;
                }
                if (attCode === 'P') {
                  color = 'rgb(182 247 182)';
                } else if (attCode === 'A') {
                  color = 'rgb(245 210 209)';
                } else if (attCode === 'H') {
                  color = 'rgb(199 237 249)'
                } else if (attCode === 'SP') {
                  color = ' #FFFFE0'
                } else if (attCode === 'PR') {
                  color = '#D3D3D3'
                  // if (params.data?.['attendance_status']?.[0].code === 'final_approved') {
                  //   color = 'rgb(182 247 182)';
                  // }
                } else {
                  color = 'rgb(255 234 191)'
                }
              }
            }
            if (params.colDef.field === 'deficit_minutes' && params.data !== undefined && params.data !== null) {
              if (params.data.deficit_minutes !== undefined && params.data.deficit_minutes !== null) {
                if (params.data.deficit_minutes < 0) {
                  fontColor = 'red';
                }
              }
              alignment = 'center';
            }
            if (params.colDef.field === 'observed_in_tmstmp' || params.colDef.field === 'observed_out_tmstmp') {
              opacity = '0.6';
              alignment = 'center';
            }
            if (params.colDef.field === 'working_minutes' || params.colDef.field === 'sites_id') {
              alignment = 'center';
            }
            if (params.colDef.field === 'attendance_date') {
              alignment = 'left';
              paddingLeft = '30px';
            }
            return {
              color: fontColor !== null ? fontColor : 'black',
              fontSize: '14px',
              border: '0.1px solid #dde2eb',
              backgroundColor: color,
              opacity: opacity,
              textAlign: alignment,
              'padding-left': paddingLeft,
            }
          },
          tooltipValueGetter: (params) => {
            let tooltipText = null;
            if (params.data !== undefined && params.data !== null && params.data.attendanceData !== undefined && params.data.attendanceData !== null && params.data.attendanceData.exception !== null && params.data.attendanceData.exception !== undefined) {
              const { convertMinutesToHours } = getConverters();
              const exception = params.data.attendanceData.exception;
              const value = params.data.attendanceData;
              const labelMap = {
                att_type: 'Attendance Type',
                shift_code: 'Shift Code',
                working_minutes: 'Working Hrs',
                overtime_minutes: 'Overtime Hrs',
                observed_in_tmstmp: 'In Time',
                observed_out_tmstmp: 'Out Time',
              };
              tooltipText = [];
              for (let i = 0; i < exception.length; i++) {
                const tooltipLines = [];
                const prevValue = exception[i];
                const currValue = exception[i + 1];
                Object.keys(labelMap).forEach((key) => {
                  if (key === 'att_type') {
                    if (currValue === undefined) {
                      if (value[key][0].id !== prevValue[key][0].id) {
                        tooltipLines.push(`${labelMap[key]}: ${prevValue[key][0].code} -> ${value[key][0].code}\n`)
                      }
                    } else if (currValue[key][0].id !== prevValue[key][0].id) {
                      tooltipLines.push(`${labelMap[key]}: ${prevValue[key][0].code} -> ${currValue[key][0].code}\n`)
                    }
                  } else if (key === 'shift_code') {
                    if (currValue === undefined) {
                      if (value['shifts_id'][0][key] !== prevValue[key]) {
                        tooltipLines.push(`${labelMap[key]}: ${prevValue[key]} -> ${value['shifts_id'][0][key]}\n`)
                      }
                    } else if (currValue[key] !== prevValue[key]) {
                      tooltipLines.push(`${labelMap[key]}: ${prevValue[key]} -> ${currValue[key]}\n`)
                    }
                  } else if (key === 'observed_in_tmstmp') {
                    if (currValue === undefined) {
                      if (value['observed_in_tmstmp'] !== prevValue[key]) {
                        tooltipLines.push(`${labelMap[key]}: ${convertUtcToIst(prevValue[key])} -> ${convertUtcToIst(value['in_time'])}\n`)
                      }
                    } else if (currValue[key] !== prevValue[key]) {
                      tooltipLines.push(`${labelMap[key]}: ${convertUtcToIst(prevValue[key])} -> ${convertUtcToIst(currValue[key])}\n`)
                    }
                  } else if (key === 'observed_out_tmstmp') {
                    if (currValue === undefined) {
                      if (value['observed_out_tmstmp'] !== prevValue[key]) {
                        tooltipLines.push(`${labelMap[key]}: ${convertUtcToIst(prevValue[key])} -> ${convertUtcToIst(value['out_time'])}\n`)
                      }
                    } else if (currValue[key] !== prevValue[key]) {
                      tooltipLines.push(`${labelMap[key]}: ${convertUtcToIst(prevValue[key])} -> ${convertUtcToIst(currValue[key])}\n`)
                    }
                  } else {
                    if (currValue === undefined) {
                      if (prevValue[key] !== value[key]) {
                        tooltipLines.push(`${labelMap[key]}: ${convertMinutesToHours(prevValue[key])} -> ${convertMinutesToHours(value[key])}\n`)
                      }
                    } else {
                      if (currValue[key] !== prevValue[key]) {
                        tooltipLines.push(`${labelMap[key]}: ${convertMinutesToHours(prevValue[key])} -> ${convertMinutesToHours(currValue[key])}\n`)
                      }
                    }
                  }
                })
                if (tooltipLines.length > 0) {
                  tooltipLines.unshift(`${tooltipText.length + 1}: ${exception[i].description}\nEdit date time:  ${convertUTCToISTDateTime(exception[i].edit_timestamp)} \n`);
                  tooltipLines.push('\n');
                  tooltipText.push(tooltipLines.join(''));
                } else {
                  tooltipLines.unshift(`${tooltipText.length + 1}: ${exception[i].description}\nEdit date time:  ${convertUTCToISTDateTime(exception[i].edit_timestamp)} \n`);
                  tooltipLines.push('\n');
                  tooltipText.push(tooltipLines.join(''));
                }
              }
              if (value['att_type'] !== undefined) {
                if (value.att_type[0].code === 'SP') {
                  if (value.out_time !== null) {
                    tooltipText.unshift(`Out Time : ${convertUtcToIst(value.out_time)}\n`)
                  }
                  if (value.in_time !== null) {
                    tooltipText.unshift(`In Time : ${convertUtcToIst(value.in_time)}\n`)
                  }
                }
              }
              tooltipText = tooltipText.length > 0 ? tooltipText.join('') : null;
            }
            if (params.data !== undefined && params.data !== null && params.data.attendanceData !== undefined && params.data.attendanceData !== null && tooltipText === null) {
              tooltipText = [];
              if (params.data.attendanceData['att_type'] !== undefined) {
                if (params.data.attendanceData.att_type[0].code === 'SP') {
                  if (params.data.attendanceData.in_time !== null) {
                    tooltipText.push(`In Time : ${convertUtcToIst(params.data.attendanceData.in_time)}\n`)
                  }
                  if (params.data.attendanceData.out_time !== null) {
                    tooltipText.push(`Out Time : ${convertUtcToIst(params.data.attendanceData.out_time)}\n`)
                  }
                }
              }
              tooltipText = tooltipText.length > 0 ? tooltipText.join('') : null;
            }
            return tooltipText;
          },
        }
      )
    }
    return columnDefs;
  }

  function registerCellValueChanged(context) {
    return async(params) => {
      const attendanceData = store.getters['agGridModule/attendanceGridRows'];
      const index = params.data.day - 1;
      const attendanceEdit = params.data.attendance_edit;
      const isMissedPunch = params.data.isMissedPunch;
      let newRecord = false;
      if (isMissedPunch) {
        for (let i = 0; i < attendanceData.length; i++) {
          if (!attendanceData[i].isMissedPunch) {
            params.data['attendanceData'] = JSON.parse(JSON.stringify(attendanceData[i].attendanceData));
            params.data['attendanceData']['attendance_date'] = getAttendanceDate(params, params.data.attendance_date);
            // params.data['sites_id'] = params.data['attendanceData'].sites_id;
            delete params.data['attendanceData'].leave_type;
            delete params.data['attendanceData'].observed_in_tmstmp;
            delete params.data['attendanceData'].observed_out_tmstmp;
            delete params.data['attendanceData'].in_timestamp;
            delete params.data['attendanceData'].overtime_minutes;
            delete params.data['attendanceData'].working_minutes;
            delete params.data['attendanceData'].att_type;
            delete params.data['attendanceData'].sites_id;
            delete params.data['attendanceData'].exception;
            delete params.data['attendanceData'].attendance_status;
            params.data.isMissedPunch = false;
            newRecord = false;
            break;
          } else {
            newRecord = true;
          }
        }
      }
      if (newRecord) {
        params.data['attendanceData'] = {};
        params.data['attendanceData']['attendance_date'] = getAttendanceDate(params, params.data.attendance_date);
        if (attendanceData.currEmployee === undefined || attendanceData.currEmployee === null) {
          if (attendanceData?.gridDataSource?.employees !== undefined && attendanceData?.gridDataSource?.employees !== null) {
            params.data['attendanceData']['employees_id'] = [attendanceData?.gridDataSource?.employees]
          } else {
            toast.warning('Please select employee to mark attendance', {
              position: toast.POSITION.TOP_CENTER,
            })
            params.data['attendanceData'] = {};
            params.data.attendanceEdit = {};
            return false;
          }
        } else {
          params.data['attendanceData']['employees_id'] = [attendanceData?.currEmployee];
        }
        params.data['attendanceData']['designation'] = [attendanceData?.gridDataSource?.designation[0]];
        params.data['attendanceData']['shifts_id'] = [attendanceData?.gridDataSource?.shifts_id[0]];
        params.data['attendanceData']['sites_id'] = [attendanceData.gridDataSource?.sites_id.find((site) => site.code == 'Mandi')];
      }
      if (Object.keys(attendanceEdit).length === 3 || Object.keys(attendanceEdit).length === 2) {
        if (attendanceEdit['sites_id'] === undefined && attendanceEdit['att_type'] !== undefined) {
          attendanceEdit['sites_id'] = [attendanceData.gridDataSource.sites_id.find((site) => site.code == 'Mandi')];
          if (attendanceEdit['att_type'][0].code === 'L') {
            attendanceEdit['employees_id'] = [attendanceData.employees]
            const key = Object.keys(attendanceEdit);
            for (const keys in params.data.attendanceData) {
              if (keys !== key[0] && keys !== key[1]) {
                attendanceEdit[keys] = params.data.attendanceData[keys];
              }
            }
            attendanceEdit['sync_timestamp'] = attendanceEdit.attendance_date;
            attendanceEdit['id'] = uuidv4();
            delete attendanceEdit.attendance_status;
            delete attendanceEdit.last_processed_by;
            delete attendanceEdit.minWorkingHours;
            delete attendanceEdit.exception;
            delete attendanceEdit.allocations_id;
            delete attendanceEdit.deficit_minutes;
            delete attendanceEdit.deleted;
            delete attendanceEdit.creation_date;
            delete attendanceEdit.last_modified;
            delete attendanceEdit.uconst;
            attendanceEdit['overtime_minutes'] = 0;
            if (attendanceEdit.observed_in_tmstmp !== undefined && attendanceEdit.observed_in_tmstmp !== null) {
              attendanceEdit['in_timestamp'] = attendanceEdit.observed_in_tmstmp;
              delete attendanceEdit.observed_in_tmstmp;
            } else {
              delete attendanceEdit.observed_in_tmstmp;
            }
            if (attendanceEdit.observed_out_tmstmp !== undefined && attendanceEdit.observed_out_tmstmp !== null) {
              attendanceEdit['out_timestamp'] = attendanceEdit.observed_out_tmstmp;
              delete attendanceEdit.observed_out_tmstmp;
            } else {
              delete attendanceEdit.observed_out_tmstmp;
            }
          }
        }
      }
      if (Object.keys(attendanceEdit).length === 1) {
        attendanceEdit['employees_id'] = [attendanceData.employees]
        attendanceEdit['sites_id'] = [attendanceData.gridDataSource.sites_id.find((site) => site.code == 'Mandi')];
        const key = Object.keys(attendanceEdit);
        for (const keys in params.data.attendanceData) {
          if (keys !== key[0]) {
            attendanceEdit[keys] = params.data.attendanceData[keys];
          }
        }
        attendanceEdit['sync_timestamp'] = attendanceEdit.attendance_date;
        attendanceEdit['id'] = uuidv4();
        delete attendanceEdit.attendance_status;
        delete attendanceEdit.last_processed_by;
        delete attendanceEdit.minWorkingHours;
        delete attendanceEdit.exception;
        delete attendanceEdit.allocations_id;
        delete attendanceEdit.leave_type;
        delete attendanceEdit.deficit_minutes;
        delete attendanceEdit.deleted;
        delete attendanceEdit.creation_date;
        delete attendanceEdit.last_modified;
        delete attendanceEdit.uconst;
        attendanceEdit['overtime_minutes'] = 0;
        if (attendanceEdit.observed_in_tmstmp !== undefined && attendanceEdit.observed_in_tmstmp !== null) {
          attendanceEdit['in_timestamp'] = attendanceEdit.observed_in_tmstmp;
          delete attendanceEdit.observed_in_tmstmp;
        } else {
          delete attendanceEdit.observed_in_tmstmp;
        }
        if (attendanceEdit.observed_out_tmstmp !== undefined && attendanceEdit.observed_out_tmstmp !== null) {
          attendanceEdit['out_timestamp'] = attendanceEdit.observed_out_tmstmp;
          delete attendanceEdit.observed_out_tmstmp;
        } else {
          delete attendanceEdit.observed_out_tmstmp;
        }
      }
      attendanceData[index] = params.data;
      params.api.refreshCells();
      await store.dispatch(
        'agGridModule/attendanceGridRowsSetter',
        attendanceData
      );
    }
  }

  function attendancePeriodColumns(timeRange, cellEditorDataSource, showSitesCellColor, showDesignationsCellColor, isEditedEnable, selectedCells, selectAll, showInOut) {
    let day = null;
    let month = null;
    let year = null;
    const today = new Date();
    month = today.getMonth();
    month++;
    year = today.getFullYear();
    if (timeRange !== null) {
      day = timeRange.day;
      month = timeRange.month;
      year = timeRange.year;
    }
    const formattedDays = formatDays(day, month, year);
    const result = [];
    for (let i = 0; i < formattedDays.length; i++) {
      const column = {
        headerName: `${formattedDays[i].formattedDay}`,
        field: `${formattedDays[i].day}${formattedDays[i].month}${formattedDays[i].year}`,
        cellRendererSelector: (params) => {
          if (params.node.rowPinned) {
            return {
              component: 'PinnedRowRenderer',
            };
          } else {
            return {
              component: 'AttendanceCellRenderer',
            };
          }
        },
        headerComponent: 'GridHeaderComponent',
        maxWidth: 65,
        cellEditor: 'cellEditor',
        cellEditorPopupPosition: 'over',
        cellEditorPopup: true,
        suppressMovable: true,
        tooltipValueGetter: (params) => {
          let tooltipText = null;
          if (params.value !== undefined && params.value !== null && params.value.exception !== null && params.value.exception !== undefined) {
            const { convertMinutesToHours } = getConverters();
            const exception = params.value.exception;
            const value = params.value;
            const labelMap = {
              att_type: 'Attendance Type',
              shift_code: 'Shift Code',
              working_minutes: 'Working Hrs',
              overtime_minutes: 'Overtime Hrs',
              observed_in_tmstmp: 'In Time',
              observed_out_tmstmp: 'Out Time',
            };
            tooltipText = [];
            for (let i = 0; i < exception.length; i++) {
              const tooltipLines = [];
              const prevValue = exception[i];
              const currValue = exception[i + 1];
              Object.keys(labelMap).forEach((key) => {
                if (key === 'att_type') {
                  if (currValue === undefined) {
                    if (value[key][0].id !== prevValue[key][0].id) {
                      tooltipLines.push(`${labelMap[key]}: ${prevValue[key][0].code} -> ${value[key][0].code}\n`)
                    }
                  } else if (currValue[key][0].id !== prevValue[key][0].id) {
                    tooltipLines.push(`${labelMap[key]}: ${prevValue[key][0].code} -> ${currValue[key][0].code}\n`)
                  }
                } else if (key === 'shift_code') {
                  if (currValue === undefined) {
                    if (value['shifts_id'][0][key] !== prevValue[key]) {
                      tooltipLines.push(`${labelMap[key]}: ${prevValue[key]} -> ${value['shifts_id'][0][key]}\n`)
                    }
                  } else if (currValue[key] !== prevValue[key]) {
                    tooltipLines.push(`${labelMap[key]}: ${prevValue[key]} -> ${currValue[key]}\n`)
                  }
                } else if (key === 'observed_in_tmstmp') {
                  if (currValue === undefined) {
                    if (value['observed_in_tmstmp'] !== prevValue[key]) {
                      tooltipLines.push(`${labelMap[key]}: ${convertUtcToIst(prevValue[key])} -> ${convertUtcToIst(value['in_time'])}\n`)
                    }
                  } else if (currValue[key] !== prevValue[key]) {
                    tooltipLines.push(`${labelMap[key]}: ${convertUtcToIst(prevValue[key])} -> ${convertUtcToIst(currValue[key])}\n`)
                  }
                } else if (key === 'observed_out_tmstmp') {
                  if (currValue === undefined) {
                    if (value['observed_out_tmstmp'] !== prevValue[key]) {
                      tooltipLines.push(`${labelMap[key]}: ${convertUtcToIst(prevValue[key])} -> ${convertUtcToIst(value['out_time'])}\n`)
                    }
                  } else if (currValue[key] !== prevValue[key]) {
                    tooltipLines.push(`${labelMap[key]}: ${convertUtcToIst(prevValue[key])} -> ${convertUtcToIst(currValue[key])}\n`)
                  }
                } else {
                  if (currValue === undefined) {
                    if (prevValue[key] !== value[key]) {
                      tooltipLines.push(`${labelMap[key]}: ${convertMinutesToHours(prevValue[key])} -> ${convertMinutesToHours(value[key])}\n`)
                    }
                  } else {
                    if (currValue[key] !== prevValue[key]) {
                      tooltipLines.push(`${labelMap[key]}: ${convertMinutesToHours(prevValue[key])} -> ${convertMinutesToHours(currValue[key])}\n`)
                    }
                  }
                }
              })
              if (tooltipLines.length > 0) {
                tooltipLines.unshift(`${tooltipText.length + 1}: ${exception[i].description}\nEdit date time:  ${convertUTCToISTDateTime(exception[i].edit_timestamp)} \n`);
                tooltipLines.push('\n');
                tooltipText.push(tooltipLines.join(''));
              } else {
                tooltipLines.unshift(`${tooltipText.length + 1}: ${exception[i].description}\nEdit date time:  ${convertUTCToISTDateTime(exception[i].edit_timestamp)} \n`);
                tooltipLines.push('\n');
                tooltipText.push(tooltipLines.join(''));
              }
            }
            if (params.value['att_type'] !== undefined) {
              if (params?.value?.att_type?.[0]?.code === 'SP') {
                if (params.value.out_time !== null) {
                  tooltipText.unshift(`Out Time : ${convertUtcToIst(params.value.out_time)}\n`)
                }
                if (params.value.in_time !== null) {
                  tooltipText.unshift(`In Time : ${convertUtcToIst(params.value.in_time)}\n`)
                }
              }
            }
            tooltipText = tooltipText.length > 0 ? tooltipText.join('') : null;
          }
          if (params.value !== null && params.value !== undefined && tooltipText === null) {
            tooltipText = [];
            if (params.value['att_type'] !== undefined) {
              if (params?.value?.att_type?.[0]?.code === 'SP') {
                if (params.value.in_time !== null) {
                  tooltipText.push(`In Time : ${convertUtcToIst(params.value.in_time)}\n`)
                }
                if (params.value.out_time !== null) {
                  tooltipText.push(`Out Time : ${convertUtcToIst(params.value.out_time)}\n`)
                }
              }
            }
            tooltipText = tooltipText.length > 0 ? tooltipText.join('') : null;
          }
          return tooltipText;
        },
        editable: (params) => {
          if (params.node.rowPinned) {
            return false;
          }
          const field = params.colDef.field;
          const data = params.data;
          const bSettings = store.getters['bSettings'];
          const env = bSettings.env;
          if (env?.code !== 'admin' && !env?.isAO && !env?.isRO && !env?.isDCO) {
            if (data[field] !== undefined) {
              if (data?.[field]?.['att_type']?.[0]?.code === 'SP') {
                return true;
              } else {
                toast.warning('Only Single Punch records can be edited', {
                  position: toast.POSITION.TOP_CENTER,
                })
                return false;
              }
            }
          }
          return true;
        },
        cellRendererParams: {
          'showSitesCellColor':showSitesCellColor,
          'showDesignationsCellColor':showDesignationsCellColor,
          'cellsSelected': selectedCells,
          'selectAllCells': selectAll,
          'showInOut': showInOut,
        },
        cellEditorParams: {
          'cellEditorDataSource':cellEditorDataSource,
          'isEditedEnable':isEditedEnable
        },
        enableCellChangeFlash: true,
        onCellValueChanged: attendanceCellValueChanged(),
        cellStyle: (params) => {
          return { fontSize: '14px' };
        },
      };
      result.push(column);
    }
    return result;
  }

  // Helper function to get the parent cell element
  function getParentCellElement(target) {
    let parent = target;
    while (parent && !parent.classList.contains('ag-cell')) {
      parent = parent.parentElement;
    }
    return parent;
  }

  async function selectedCellsSetter(store, value, selectedCells, selectAll, registerView) {
    let cellsSelected = selectedCells;
    let selectAllCells = selectAll;
    const { data, rowPinned, column, event, api, node } = value;
    let colId = column.colId;
    let employee = data['employees'];
    let newCell = false;
    const attendanceData = store.getters['agGridModule/attendanceGridRows'];
    if (registerView) {
      // if (!(event.ctrlKey && ((event.keyCode !== undefined && event.keyCode === 65) || (event.keyCode !== undefined && event.keyCode === 97)))) {
      //   return false;
      // }
      if (data.attendanceData === undefined || data.attendanceData === null) {
        employee = [attendanceData.employees];
        if (attendanceData.employees === undefined || attendanceData.employees === null) {
          if (attendanceData.currEmployee === undefined || attendanceData.currEmployee === null) {
            if (attendanceData?.gridDataSource !== undefined  && attendanceData.gridDataSource !== null && attendanceData.gridDataSource.employees !== undefined && attendanceData.gridDataSource.employees !== null) {
              employee = [attendanceData?.gridDataSource?.employees]
              attendanceData['employees'] = attendanceData?.gridDataSource?.employees;
            } else {
              toast.warning('Please select employee to mark attendance', {
                position: toast.POSITION.TOP_CENTER,
              })
            }
          } else {
            employee = [attendanceData?.currEmployee]
            attendanceData['employees'] = attendanceData?.currEmployee;
          }
        }
        data['attendanceData'] = {
          'attendance_date': getAttendanceDate(column, data.attendance_date),
          'employees_id': [attendanceData.employees],
          'id': uuidv4(),
        }
      } else {
        employee = data?.attendanceData?.employees_id;
      }
      if (employee[0] === undefined) {
        return false;
      }
      colId = data.day;
    }

    // Handle Ctrl+A (select all)
    if (event.ctrlKey && (event.keyCode === 65 || event.keyCode === 97)) {
      const selectedNodes = api.getSelectedNodes();
      const cellRendererInstances = api.getCellRendererInstances();
      const isSelectAll = selectAllCells;

      if (!registerView) {
        cellRendererInstances.forEach((instance) => {
          const { eGridCell, column, node } = instance.params;
          if (!['employees', 'total'].includes(column.colId) && node.rowPinned === undefined) {
            eGridCell.classList.toggle('ag-row-selected', !isSelectAll);
          }
        });
      }

      cellsSelected = isSelectAll ? [] : selectedNodes.map((node) => {
        const clone = JSON.parse(JSON.stringify(node.data));
        if (registerView) {
          if (clone.attendanceData === undefined || clone.attendanceData === null) {
            employee = [attendanceData.employees];
            if (attendanceData.employees === undefined || attendanceData.employees === null) {
              if (attendanceData.currEmployee === undefined || attendanceData.currEmployee === null) {
                if (attendanceData?.gridDataSource !== undefined  && attendanceData.gridDataSource !== null && attendanceData.gridDataSource.employees !== undefined && attendanceData.gridDataSource.employees !== null) {
                  employee = [attendanceData?.gridDataSource?.employees]
                  attendanceData['employees'] = attendanceData?.gridDataSource?.employees;
                } else {
                  toast.warning('Please select employee to mark attendance', {
                    position: toast.POSITION.TOP_CENTER,
                  })
                }              } else {
                attendanceData['employees'] = attendanceData?.currEmployee;
              }
            }
            clone['attendanceData'] = {
              'attendance_date': getAttendanceDate(column, clone.attendance_date),
              'employees_id': [attendanceData.employees],
              'id': uuidv4(),
            }
          }
          colId = clone.day;
          if (employee === undefined || employee === null) {
            employee = clone.attendanceData.employees_id;
          }
          return { [employee[0].id] : {[colId]: clone.attendanceData}}
        }
        delete clone.employees;
        return { [node.data.employees[0].id]: clone };
      });
      if (registerView) {
        const merged = {};
        cellsSelected.forEach((item) => {
          const key = Object.keys(item)[0];
          const value = item[key];
          if (merged[key]) {
            Object.assign(merged[key], value);
          } else {
            merged[key] = value;
          }
        });

        // Convert the merged object back to the desired array format
        cellsSelected = [merged];
      }
      selectAllCells = !isSelectAll;
    } else if (colId === 'employees') {
      const isSelected = node.isSelected();
      const cellRendererInstances = api.getCellRendererInstances({ column: api.getColumnDefs(), rowNodes: [node] });

      if (!registerView) {
        cellRendererInstances.forEach((instance) => {
          const { eGridCell, column } = instance.params;
          if (!['employees', 'total'].includes(column.colId)) {
            eGridCell.classList.toggle('ag-row-selected', isSelected);
          }
        });
      }

      if (isSelected) {
        const employeeId = employee[0].id;
        cellsSelected = cellsSelected.filter((obj) => Object.keys(obj)[0] !== employeeId);

        const selectedData = Object.keys(data).reduce((acc, key) => {
          if (key !== 'employees') {
            acc[key] = data[key];
          }
          return acc;
        }, {});

        if (Object.keys(selectedData).length > 0) {
          cellsSelected.push({ [employeeId]: selectedData });
          newCell = true;
        }
      } else {
        cellsSelected = cellsSelected.filter((obj) => Object.keys(obj)[0] !== employee[0].id);
        selectAllCells = false;
      }
    } else if (rowPinned === undefined) {
      const employeeId = employee[0].id;
      let selectedCell = data[colId];
      if (registerView) {
        selectedCell = data.attendanceData;
      }

      const existingEntry = cellsSelected.find((obj) => Object.keys(obj)[0] === employeeId);
      if (existingEntry) {
        if (existingEntry[employeeId][colId] === undefined) {
          existingEntry[employeeId][colId] = selectedCell;
          newCell = true;
        } else {
          delete existingEntry[employeeId][colId];
          newCell = false;
        }
      } else {
        newCell = true;
        cellsSelected.push({ [employeeId]: { [colId]: selectedCell } });
      }

      if (data[colId]?.['att_type']?.[0]?.code === 'L' && data[colId]?.['att_type']?.[0]?.code === 'LWP' && data[colId]?.['att_type']?.[0]?.code === 'HD') {
        if (newCell) {
          const colIds = [];
          for (const key in data) {
            if (key !== 'employees' && key !== colId) {
              colIds.push(key)
            }
          }
          getAdjacentDays(colId, colIds)
        }
      }

      if (!registerView) {
        const parentCell = getParentCellElement(event.target);
        if (parentCell && !registerView) {
          parentCell.classList.toggle('ag-row-selected', newCell);
          if (!newCell) selectAllCells = false;
        }
      }
    } else {
      selectAllCells = false;
    }

    // Filter out empty objects
    cellsSelected = cellsSelected.filter((item) => {
      return Object.values(item).some((obj) => Object.keys(obj).length > 0);
    });

    if (selectAllCells) {
      if (cellsSelected.length === 0) {
        selectAllCells = false;
      }
    }

    return { cellsSelected, selectAllCells };
  }

  function getAdjacentDays(dateString, dateArray) {
    console.log('I am here key', dateString, dateArray)
  }

  async function makeParamsToSend(
    txn,
    bulkTxnName,
    currentFolder,
    bSettings,
    selectedRows
  ) {
    const sessionId = store.getters['sessionIdGetter'];
    const dataToChange = [];
    let docpSelected = [];
    const txnName = bulkTxnName;
    let propertyName = null;
    let docpSource = null;
    const { changeWorkingFolder } = foldersUtility();
    let docpSourceFolder = null;
    propertyName = 'attendance_status';
    docpSource = currentFolder.fields[propertyName]?.source;
    docpSourceFolder = changeWorkingFolder(
      docpSource,
      bSettings?.output.data.records[0]
    );
    //getting the docpId
    const { generateFilterParams } = makeFilter(txnName, docpSourceFolder, null, null, true);
    const filterParams = generateFilterParams();
    const txnParams = {
      filters: filterParams,
      getRowCount: true,
      refRows: true,
    };
    const { listData } = postData();
    const list = await listData(docpSourceFolder, txnParams);
    if (list.output.type === 'error') {
      console.log(list.output.message);
    } else {
      if (list.output.data.records.length > 0) {
        const { fatRowGenerator } = getFatRow();
        docpSelected = fatRowGenerator(list);
      }
    }
    //loop thru each selected row to create formData
    const missedPunchIds = [];
    let isMissedPunch = false;
    for (let i = 0; i < selectedRows.length; i++) {
      const key = Object.keys(selectedRows[i]);
      for (const obj in selectedRows[i][key[0]]) {
        if (selectedRows[i][key[0]]?.[obj]?.attendance_status !== undefined) {
          dataToChange.push({
            oldDocpId: selectedRows[i][key?.[0]]?.[obj]?.attendance_status?.[0].id,
            newId: docpSelected[0].id,
            txnOp: 'U',
            mainFolderId: selectedRows[i][key[0]][obj].id,
            propertyName: propertyName,
          });
        } else {
          if (bulkTxnName !== 'reject') {
            missedPunchIds.push({
              id: selectedRows[i][key[0]][obj]?.id,
              employees_id: [selectedRows[i][key[0]][obj]?.employees_id?.[0]?.id],
              attendance_date: selectedRows[i][key[0]][obj]?.attendance_date
            });
            dataToChange.push({
              //oldDocpId: docpSelected[2].id,
              newId: docpSelected[0].id,
              //txnOp: 'U',
              txnOp: 'C',
              mainFolderId: selectedRows[i][key[0]][obj]?.id,
              propertyName: propertyName,
            });
          } else {
            isMissedPunch = true;
            delete selectedRows[i][key[0]][obj];
          }
        }
      }
    }

    const formData = dataToChange;
    //generate the txnToRun with txn and fieldsData
    const { generateTxn } = makeTxnForBulk(
      formData,
      currentFolder,
      txn,
      txnName
    );
    const txnToRun = generateTxn();
    txnToRun.session_key = sessionId;
    txnToRun.foldertype = 'mviews';
    if (missedPunchIds.length > 0) {
      const params = txnToRun.params;
      for (let i = 0; i < missedPunchIds.length; i++) {
        const dataStart = txnToRun.params[txnToRun.params.length - 1].dataStart;
        const dataEnd = txnToRun.params[txnToRun.params.length - 1].dataEnd;
        for (let j = dataStart; j < dataEnd; j++) {
          if (missedPunchIds[i].id === params[j].attendance_id) {
            const index = params[txnToRun.params.length - 1][params[j].attendance_id];
            let formattedIndex = index.split('_')[0];
            if (isNaN(formattedIndex)) {
              formattedIndex = Number(formattedIndex);
            }
            params[formattedIndex]['attendance_date'] = missedPunchIds[i].attendance_date;
            params[formattedIndex]['employees_id'] = missedPunchIds[i].employees_id;
          }
        }
      }
      txnToRun.params = params;
    } else if (bulkTxnName === 'reject' && isMissedPunch) {
      toast.warning('Missed Punch cannot be rejected', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    const response = await sendMessage(txnToRun);
    return response;
  }

  async function getUnProcessedRows(selectedRows, gridApi) {
    try {
      await reloadGridData(gridApi);
      const attRows = store.getters['agGridModule/attendanceGridRows'];
      const unProcessedRows = selectedRows.filter((row) => {
        const key = Object.keys(row)[0];
        if (row[key] != null) {
          let remainingAttRow = [];
          for (const idx in row[key]) {
            const att_row = row[key][idx];
            const filteredRows = attRows.filter((attRow) => {
              return attRow.attendance_date == getGridDate(att_row.attendance_date) &&
         attRow.attendance_status != null &&
         attRow.attendance_status[0] != null &&
               attRow.attendance_status[0].code == 'unapproved';
            });
            remainingAttRow = remainingAttRow.concat(filteredRows);
          }
          return remainingAttRow.length > 0;
        }
        else {
          return false;
        }
      });
      return unProcessedRows;
    }
    catch (err) {
      throw err;
    }
  }

  async function fetchDataThroughPollingLoop(selectedRows, gridApi) {
    try {
      let worstCaseCount = 0;
      let unProcessedRows = await getUnProcessedRows(selectedRows, gridApi);
      while (unProcessedRows.length > 0 && worstCaseCount < 5) {  // if something goes wrong on server side(mview processing) this worstCaseCount will prevent the while loop from going in infinite mode
        unProcessedRows = await getUnProcessedRows(unProcessedRows, gridApi);
        worstCaseCount += 1;
      }

      if (unProcessedRows.length == 0) {
        selectedRows = [];
        const selected = gridApi.getSelectedNodes();
        for (let i = 0; i < selected.length; i++) {
          selected[i].setSelected(false);
        }
      }
    }
    catch (err) {
      console.log(err);
    }
  }

  function reloadGridData(gridApi, timeout = 10000) {
    return new Promise((resolve, reject) => {
      let timeoutId;

      // Handler for the "modelUpdated" event
      function onModelUpdated() {
        clearTimeout(timeoutId); // Clear the timeout
        gridApi.removeEventListener('modelUpdated', onModelUpdated); // Clean up listener
        resolve(); // Resolve the promise
      }

      // Setup a timeout to reject the promise if data reload takes too long
      timeoutId = setTimeout(() => {
        gridApi.removeEventListener('modelUpdated', onModelUpdated); // Clean up listener
        reject(new Error('Data reload timed out'));
      }, timeout);

      // Attach event listener for model update
      gridApi.addEventListener('modelUpdated', onModelUpdated);

      // Trigger the cache refresh
      try {
        gridApi.refreshInfiniteCache();
      } catch (error) {
        clearTimeout(timeoutId); // Clear timeout in case of error
        gridApi.removeEventListener('modelUpdated', onModelUpdated); // Clean up listener
        reject(error); // Reject promise with the error
      }
    });
  }

  function getGridDate(utcDate) {
    const date = utcDate;
    const formattedDate = new Date(date);
    formattedDate.setMinutes(formattedDate.getMinutes() + 330);
    const day = formattedDate.getDate();
    const month = formattedDate.getMonth() + 1;
    const year = formattedDate.getFullYear();
    return `${day}/${month}/${year}`;
  }

  async function getUnProcessedTxns(txnList, gridApi) {
    try {
      await reloadGridData(gridApi);
      const attRows = store.getters['agGridModule/attendanceGridRows'];
      const unProcessedTxns = txnList.filter((txn) => {
        if (txn.params[3] != null) { //using 3rd index because data rows starts from this index in patch
          const attDate = getGridDate(txn.params[3].attendance_date);
          const updatedAttTypeId = txn.params[3].att_type[0];
          const remainingAttRow = attRows.filter((attRow) => {
            return attRow.attendance_date == attDate && (
              attRow.att_type == null ||
       attRow.att_type != null &&
       attRow.att_type[0] != null &&
       attRow.att_type[0].id != updatedAttTypeId);
          });
          return remainingAttRow.length > 0;
        }
        else {
          return false;
        }
      });
      return unProcessedTxns;
    }
    catch (err) {
      throw err;
    }
  }

  /*function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }*/

  async function fetchDataThroughPollingLoop1(txnList, gridApi) {
    try {
      if (txnList.length == 0) {
        return;
      }

      let worstCaseCount = 0;
      let unProcessedTxns = await getUnProcessedTxns(txnList, gridApi);
      while (unProcessedTxns.length > 0 && worstCaseCount < 5) { // if something goes wrong on server side(mview processing) this worstCaseCount will prevent the while loop from going in infinite mode
        //await delay(1000);
        unProcessedTxns = await getUnProcessedTxns(unProcessedTxns, gridApi);
        worstCaseCount += 1;
      }
    }
    catch (err) {
      console.log(err);
    }
  }

  async function approveAttendance(store, gridApi, selectedCells, txnName) {
    let approveNotification = null;
    let selectedRows = selectedCells;
    let approvedCells = 0;
    let isPA = false;
    for (let i = 0; i < selectedRows.length; i++) {
      const key = Object.keys(selectedRows[i]);
      for (const obj in selectedRows[i][key[0]]) {
        if (
          selectedRows[i][key[0]][obj]?.attendance_status?.[0].code === 'final_approved'
        ) {
          approvedCells++;
          delete selectedRows[i][key[0]][obj];
        } else if (selectedRows[i][key[0]][obj]?.att_type?.[0]?.code === 'SP') {
          isPA = true;
          delete selectedRows[i][key[0]][obj];
        }
      }
    }
    for (let i = 0; i < selectedRows.length; i++) {
      for (const obj in selectedRows[i]) {
        if (Object.keys(selectedRows[i][obj]).length === 0) {
          delete selectedRows[i][obj];
        }
      }
    }
    selectedRows = selectedRows.filter((row) => Object.keys(row).length !== 0);
    if (isPA) {
      toast.warning('Single Punch cannot be approved', {
        position: toast.POSITION.TOP_CENTER,
      });
      return [];
    }
    if (approvedCells > 0) {
      toast.warning(`${approvedCells} attendance cells are already approved`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    if (selectedRows.length > 0) {
      approveNotification = toast.loading('Approving attendance...', {
        position: toast.POSITION.TOP_CENTER,
      });
      const bSettings = store.getters['bSettings'];
      const txn = JSON.parse(
        JSON.stringify(
          bSettings.output.data.records[0].business.containers.mviews.folders
            .attendance.txns.txn_attendance_approve
        )
      );
      const bulkTxnName = txnName !== undefined && txnName !== null ? txnName : 'approve';
      const currentFolder =
        bSettings.output.data.records[0].business.containers.mviews.folders
          .attendance;
      const response = await makeParamsToSend(
        txn,
        bulkTxnName,
        currentFolder,
        bSettings,
        selectedRows
      );
      if (response.output.type === 'error') {
        toast.update(approveNotification, {
          render: response.output.message,
          autoClose: 4000,
          closeButton: true,
          type: 'error',
          isLoading: false,
        });
      } else {
        if (response.output.type === 'success') {
          toast.update(approveNotification, {
            render: 'attendance cells approved',
            autoClose: 4000,
            closeButton: true,
            type: 'success',
            isLoading: false,
          });
          await fetchDataThroughPollingLoop(selectedRows, gridApi);
        }
      }
    } else {
      toast.warning('No cells to approve', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    return selectedRows;
  }

  async function rejectAttendance(store, gridApi, selectedCells) {
    let rejectNotification = null;
    let selectedRows = selectedCells;
    let rejectedCells = 0;
    let isPA = false;
    let isPresent = false;
    for (let i = 0; i < selectedRows.length; i++) {
      const key = Object.keys(selectedRows[i]);
      for (const obj in selectedRows[i][key[0]]) {
        if (
          selectedRows[i][key[0]][obj]?.attendance_status?.[0].code === 'rejected'
        ) {
          rejectedCells++;
          delete selectedRows[i][key[0]][obj];
        } else if (selectedRows[i][key[0]][obj]?.att_type?.[0]?.code === 'SP') {
          isPA = true;
          delete selectedRows[i][key[0]][obj];
        } else if (selectedRows[i][key[0]][obj]?.att_type?.[0]?.code === 'P') {
          isPresent = true;
          delete selectedRows[i][key[0]][obj];
        } else if (
          selectedRows[i][key[0]][obj]?.attendance_status?.[0].code === 'final_approved'
        ) {
          delete selectedRows[i][key[0]][obj];
        }
      }
    }
    if (isPA) {
      toast.warning('Single Punch cannot be rejected', {
        position: toast.POSITION.TOP_CENTER,
      });
      return [];
    }
    if (isPresent) {
      toast.warning('Present attendance cannot be rejected', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    if (rejectedCells > 0) {
      toast.warning(`${rejectedCells} attendance cells are already rejected`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    for (let i = 0; i < selectedRows.length; i++) {
      for (const obj in selectedRows[i]) {
        if (Object.keys(selectedRows[i][obj]).length === 0) {
          delete selectedRows[i][obj];
        }
      }
    }
    selectedRows = selectedRows.filter((row) => Object.keys(row).length !== 0);
    if (selectedRows.length > 0) {
      rejectNotification = toast.loading('Rejecting attendance...', {
        position: toast.POSITION.TOP_CENTER,
      });
      const bSettings = store.getters['bSettings'];
      const txn = JSON.parse(
        JSON.stringify(
          bSettings.output.data.records[0].business.containers.mviews.folders
            .attendance.txns.txn_attendance_reject
        )
      );
      const bulkTxnName = 'reject';
      const currentFolder =
        bSettings.output.data.records[0].business.containers.mviews.folders
          .attendance;
      const response = await makeParamsToSend(
        txn,
        bulkTxnName,
        currentFolder,
        bSettings,
        selectedRows
      );
      if (response.output.type === 'error') {
        toast.update(rejectNotification, {
          render: response.output.message,
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
          closeButton: true,
          type: 'error',
          isLoading: false,
        });
      } else {
        if (response.output.type === 'success') {
          toast.update(rejectNotification, {
            render: 'attendance cells rejected',
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
            closeButton: true,
            type: 'success',
            isLoading: false,
          });
        }
        await fetchDataThroughPollingLoop(selectedRows, gridApi);
      }
    } else {
      toast.warning('No cells to reject', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    return selectedRows;
  }

  async function save(store, isRegisterView, gridApi) {
    const successList = [];
    let saveNotification = null;
    if (isRegisterView) {
      const attendanceData = store.getters['agGridModule/attendanceGridRows'];
      for (let i = 0; i < attendanceData.length; i++) {
        if (attendanceData[i]['attendance_edit'] !== undefined) {
          if ('sites_id' in attendanceData[i]['attendance_edit'] && 'att_type' in attendanceData[i]['attendance_edit']) {
            const txnData = txnDataSetter(attendanceData[i]['attendance_edit']);
            await generateTxn(txnData);
            delete attendanceData[i]['attendance_edit'];
          } else {
            toast.warning('Please fill all the fields', {
              position: toast.POSITION.TOP_CENTER,
            })
            return null;
          }
        }
      }
    }
    const txnList = store.getters['agGridModule/gridTxnsGetter'];
    if (txnList.length > 0) {
      saveNotification = toast.loading('Updating attendance...', {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      toast.warning('Nothing to save', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    for (let i = 0; i < txnList.length; i++) {
      const res = await sendMessage(txnList[i]);
      if (res.output.type === 'success') {
        successList.push(txnList[i]);
        toast.update(saveNotification, {
          render: 'attendance marked',
          position: toast.POSITION.TOP_CENTER,
          autoClose: 4000,
          closeButton: true,
          type: 'success',
          isLoading: false,
        });
      }
      else if (res.output.type === 'error') {
        toast.update(saveNotification, {
          render: res.output.message,
          position: toast.POSITION.TOP_CENTER,
          autoClose: 4000,
          closeButton: true,
          type: 'error',
          isLoading: false,
        });
        await reloadGridData(gridApi);
      }
    }
    await fetchDataThroughPollingLoop1(successList, gridApi);
    store.commit('agGridModule/gridTxnsMutations', []);
  }

  function getAttendanceDate(params, date) {
    let str = params.colDef.field;
    if (date !== null) {
      str = date;
    }
    const dateRegex1 = /(\d{1,2})([A-Za-z]{3})(\d{4})/;
    const dateRegex2 = /(\d{1,2})\/(\d{1,2})\/(\d{4})/;

    // Extract the parts of the date using the regex
    let month = null;
    let day = null;
    let attYear = null;
    let matches;

    if ((matches = str.match(dateRegex2))) {
      day = matches[1];
      month = matches[2];
      attYear = matches[3];
    }
    else if ((matches = str.match(dateRegex1))) {
      day = matches[1];
      const monthAbbr = matches[2];
      attYear = matches[3];

      const monthMap = {
        Jan: 1,
        Feb: 2,
        Mar: 3,
        Apr: 4,
        May: 5,
        Jun: 6,
        Jul: 7,
        Aug: 8,
        Sep: 9,
        Oct: 10,
        Nov: 11,
        Dec: 12
      };

      month = monthMap[monthAbbr];
    } else {
      console.log('Invalid date format');
      return null;
    }
    const dateObject = new Date(Date.UTC(attYear, month - 1, day, 0, 0, 0)); // Month is 0-based in JavaScript
    const dateString = dateObject.toISOString().replace('T', ' ').slice(0, 19);
    const inputDate = new Date(dateString);

    // Subtract 5 hours and 30 minutes
    inputDate.setHours(inputDate.getHours() - 5);
    inputDate.setMinutes(inputDate.getMinutes() - 30);
    const year = inputDate.getFullYear();
    const month1 = String(inputDate.getMonth() + 1).padStart(2, '0');
    const day1 = String(inputDate.getDate()).padStart(2, '0');
    const hours = String(inputDate.getHours()).padStart(2, '0');
    const minutes = String(inputDate.getMinutes()).padStart(2, '0');
    const seconds = String(inputDate.getSeconds()).padStart(2, '0');

    // Format the output
    const formattedResult = `${year}-${month1}-${day1} ${hours}:${minutes}:${seconds}`;

    // const formattedResult = inputDate.toISOString().replace("T", " ").slice(0, -5);
    return formattedResult;
    // const uuid = uuidv4();
  }

  function txnDataSetter(value) {
    const txnData = {};
    const editData = JSON.parse(JSON.stringify(value));
    if (editData['employees_id'] === undefined || editData['employees_id'] === null || editData['employees_id'][0] === undefined || editData['employees_id'][0] === null) {
      return {}
    }
    const empId = editData['employees_id'][0].id;
    txnData[empId] = [];
    const edits = {};
    const attendanceKeys = Object.keys(editData).filter((key) => key);
    attendanceKeys.forEach((key) => {
      const value = editData[key];
      const item = value;
      if (Array.isArray(item) && item.length > 0) {
        item[0]['type'] =  'docpicker';
        item[0]['oldDocpId'] = null;
        item[0]['docpNotSet'] = true;
        item[0]['detailType'] = false;
        item[0]['folderId'] = null;
        item[0]['docpType'] = true;
      }
      const isDateOrTimestamp = ['attendance_date', 'sync_timestamp', 'in_timestamp', 'out_timestamp'].includes(key);
      edits[key] = {
        path: 'attendance_edits.' + key,
        value: item,
        mandatory: false,
        ...(isDateOrTimestamp && {
          patchPath: isDateOrTimestamp ? 'business.folders.attendance_edits' : null
        })
      };
    });
    txnData[empId].push(edits);
    return txnData;
  }

  function attendanceCellValueChanged(context) {
    return async(params) => {
      if (!params?.newValue?.editing) {
        const attendance_edit = params.newValue.attendance_edit;
        const txnData = txnDataSetter(attendance_edit);
        await generateTxn(txnData);
      }
    };
  }

  async function generateTxn(txnData) {
    if (Object.keys(txnData).length !== 0) {
      const bSettings = store.getters['bSettings'];
      const { getAllFoldersList, getCurrentFolder } = getFolders();
      let txns = {};
      const folderList = getAllFoldersList(bSettings);
      const currentFolder = getCurrentFolder('attendance_edits', folderList);
      const sessionId = store.getters['sessionIdGetter'];
      for (const key in txnData) {
        for (let i = 0; i < txnData[key].length; i++) {
          const formData = txnData[key][i];
          const { getGroupedFormData } = processFormData();
          const {
            normalFieldList,
            docpList,
            detailList,
            detailKeyFieldName,
          } = getGroupedFormData(formData, currentFolder, undefined);
          //generate the txnToRun with txn and fieldsData
          const params = {
            normalFieldList: normalFieldList,
            docpList: docpList,
            detailList: detailList,
            folderDetails: currentFolder,
            currentTaskName: 'create',
            detailKeyName: detailKeyFieldName,
            txnType: undefined,
            currentFolderId: undefined,
            txn: currentFolder.txns.txn_attendance_edits_create,
          };
          const { generateTxn } = makeTxn(params);
          const txnToRun = generateTxn();
          txnToRun.session_key = sessionId;
          //run the txn
          txns = txnToRun;
        }
      }
      await store.dispatch('agGridModule/gridTxnsSetter', JSON.parse(JSON.stringify(txns)));
    }
  }

  //filters data

  async function getDocpickerList(
    docpOptions,
  ) {
    try {
      let cellEditorDataSource = {};
      const bSettings = store.getters['bSettings'];
      const { getAllFoldersList, getCurrentFolder } = getFolders();
      const folderList = getAllFoldersList(bSettings);
      const {projectionForFolder} = getProjections();
      let currentDocpFolder = [];
      let txnParams = null;
      const filterParams = null;
      let folderName = null;
      if (!docpOptions.source?.folder) {
        folderName = docpOptions.name;
      } else {
        folderName = docpOptions.source.folder;
      }
      currentDocpFolder = getCurrentFolder(folderName, folderList);
      if (currentDocpFolder) {
        if (txnParams !== null) {
          txnParams.filters = filterParams;
        } else {
          txnParams = { refRows: true };
        }
        txnParams.projections = projectionForFolder(folderName);
        const { listData } = postData();
        const docpList = await listData(currentDocpFolder, txnParams);
        if (docpList.output.type === 'error') {
          console.log(docpList.output.message);
        } else {
          const { fatRowGenerator } = getFatRow();
          const fatRow = fatRowGenerator(docpList);
          const docPObj = cellEditorDataSource;
          const key = docpOptions.name;
          docPObj[key] = fatRow;
          cellEditorDataSource = docPObj;
        }
      }
      return cellEditorDataSource
    }
    catch (e) {
      console.log(e);
    }
  }

  async function getAOandRoList() {
    const reportingMangerOptions = [];
    const filterInput = [];
    const {projectionForFolder} = getProjections();

    filterInput.push({
      operator: '=',
      path: 'employees.groups_id.code',
      value: 'RO'
    })
    const txnParams = {
      filters: {
        joinop: 'or',
      },
      getRowCount: true,
      refRows: true,
      projections: projectionForFolder('employees_id')
    }
    txnParams.filters['filterInput'] = filterInput;
    const bSettings = store.getters['bSettings'];
    const empFolder = bSettings?.output?.data?.records[0]?.business?.containers?.folders?.folders?.employees;
    const { listData } = postData();
    const empList = await listData(empFolder, txnParams);
    if (empList.output.type === 'error') {
      console.log(empList.output.message);
    } else {
      const { fatRowGenerator } = getFatRow();
      const fatRow = fatRowGenerator(empList);
      for (let i = 0; i < fatRow.length; i++) {
        reportingMangerOptions.push({
          name: fatRow[i]?.persons_id[0]?.first_name?.eng,
          id: fatRow[i].id,
          code: fatRow[i]?.code,
          label: `${fatRow[i]?.persons_id[0]?.first_name?.eng} (${fatRow[i]?.code})`
        });
      }
    }
    const list = {
      'reportingMangerList': reportingMangerOptions
    }
    return list;
  }


  const getProjections = () => {

    const projectionsList = {
      'sites' : {
        'sites': ['id', 'name', 'code', 'site_code']
      },
      'shifts' : {
        'shifts': ['id', 'name', 'shift_code', 'shift_time']
      },
      'departments': {
        'departments': ['id', 'code', 'name']
      },
      'products': {
        'designations' : ['id', 'code', 'name', 'deleted', 'creation_date', 'last_modified']
      },
      'attendance_types' : {
        'attendance_types' : ['id', 'code', 'name', 'source', 'deleted', 'last_modified']
      },
      'contracts' : {
        'contracts' : ['id', 'date_of_previous_month', 'name']
      },
      'employees_id' : {
        'employees': ['id', 'code', 'deleted', 'persons_id', 'working_hours', 'working_days'],
        'employees.persons_id': ['id', 'deleted', 'last_name', 'birth_date', 'first_name', 'middle_name', 'gender'],
        'employees.persons_id.gender': ['id', 'code', 'name',  'deleted', 'last_modified'],
      }
    }
    function projectionForFolder(folderName) {
      return projectionsList[folderName] ? projectionsList[folderName] : {}
    }
    return {projectionForFolder}
  }

  return {
    createTimeRange,
    getRandomColor,
    selectedCellsSetter,
    approveAttendance,
    rejectAttendance,
    save,
    formatDays,
    convertUtcToIst,
    txnDataSetter,
    attendanceCellValueChanged,
    attendanceRegisterColumns,
    generateTxn,
    attendancePeriodColumns,
    getTotalDays,
    formatHrsAndDays,
    convertMinutesToHours,
    convertMinutesToHoursFormat,
    deficitMinutesToHours,
    timeStringToMinutes,
    getDisplayStringForHrs,
    quickSort,
    getAttendanceDate,
    getDocpickerList,
    getAOandRoList,
  };
};

export default attendanceGridUtils;
