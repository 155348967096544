<template>
  <WfmToolbar custom-class="action-list">
    <template #left-utility>
      <div class="d-flex">
        <WfmActionsList
          :actions="actions"
          :data-test-id="'reportsView'"
          @action-click="performAction"
        />
      </div>
    </template>
    <template #right-utility>
      <div class="d-flex">
        <div
          class="ms-2 my-auto me-1"
          @click="sortTheList"
        >
          <span>Sort List</span>
          <v-icon
            :name="sortIcon"
            scale="1.2"
          />
        </div>
        <div
          v-if="filterApplicable"
          class="me-2 d-flex"
        >
          <button
            v-if="showFilters"
            class="filter-button bg-success p-1 rounded me-2"
            @click="applyFilters()"
          >
            Apply Filter
          </button>
          <button @click="toggleFilterView()">
            <v-icon
              fill="white"
              :name="filterIcon"
              scale="1.4"
              class="my-auto"
            />
          </button>
          <v-tooltip
            v-if="!showFilters"
            activator="parent"
            location="left"
          >
            Filter Reports
          </v-tooltip>
        </div>
      </div>
    </template>
  </WfmToolbar>
  <Transition name="slide-fade">
    <div
      v-if="showFilters"
      class="filter-box"
    >
      <WfmFieldGroup
        :params="params"
        :fields-to-display="fieldsToDisplay"
        :form-object-found="false"
        :base-folder="baseFolder"
        current-task="update"
      />
    </div>
  </Transition>
  <!-- eslint-disable vue/no-v-html -->
  <div
    class="report-view"
    v-html="reportTemplate"
  />
  <WfmLoader v-if="loading" />
  <WfmPagination
    :records-showing="recordsShowing"
    :total-rows-count="totalRowsCount"
    @get-page="getTheReport"
  />
  <!--eslint-enable-->
</template>

<script>
import WfmToolbar from '../../common/wfm-toolbar.vue'
import { onMounted, ref, computed, provide} from 'vue';
import {keys} from '../../provideKeys.js'
import { useStore } from 'vuex';
import getFolders from '../../composables/getFolders'
import { sendMessage } from '../../services/websocket';
import WfmFieldGroup from '../wfm-folders/wfm-fieldGroup.vue';
import { toast } from 'vue3-toastify';
import WfmPagination from '../../common/wfm-pagination.vue';
import WfmLoader from '../../common/loader/wfm-loader.vue';
import getUtility from '../../composables/getUtility'
import reportsUtility from '../../composables/reportsData';
import WfmActionsList from '../../common/wfm-actions-list.vue';
import { usePaths } from '../../composables/useFilePath.js';
export default {
  name: 'ReportsView',
  components: {
    WfmToolbar,
    WfmFieldGroup,
    WfmPagination,
    WfmLoader,
    WfmActionsList
  },
  props:{
    dataPassed:{
      type:String,
      default:null
    }
  },
  setup(props) {
    //Variables
    const store = useStore()
    const {getReportsData} = reportsUtility()
    const showFilters = ref(false)
    const reportTemplate = ref(null)
    const loading = ref(false)
    const limit = ref(50)
    const offset = ref(0)
    const recordsShowing = ref(0)
    const totalRowsCount = ref(0)
    const { getNullObject } = getUtility()
    const enteredFilter = ref()
    const sortingFlag = ref(null)

    // //computed
    const data = computed(() => {
      const retVal = props.dataPassed ? props.dataPassed : getReportsData()
      return JSON.parse(retVal)
    })
    const sortIcon = computed(() => {
      return sortingFlag.value ? 'bi-sort-alpha-down' : 'bi-sort-alpha-up';
    })

    const uiFilters = ref(JSON.parse(JSON.stringify(data.value.config.ui_filters)))

    const fieldsToDisplay = computed(() => {
      const retVal = uiFilters.value.map((each) => {
        if (each.fieldOptions?.type === 'timestamp') {
          each.fieldOptions.format_expr = null;
        }
        each.reportsFilter = true
        return each;
      }).filter((each) => each?.visible_expr !== false);

      return retVal


    });

    const filterApplicable = computed(() => {
      return fieldsToDisplay.value.length > 0
    })

    const params = ref({reportFilter:true, pickerType:data.value?.config?.ui_filters?.[0]?.filterType})

    const formData = ref({})
    provide(keys.formData, formData)

    const bSettings = computed(() => {
      const bSettingString = store.getters['bSettings'];
      return bSettingString;
    });
    const swhandle = computed(() => {
      return store.getters['userModule/swHandle']
    })
    const filterIcon = computed(() => {
      return showFilters.value ? 'md-filteraltoff' : 'md-filteralt-sharp'
    })
    const actions = computed(() => {
      return [
        {
          name: 'Action',
          actions: [
            {
              name: 'Export As PDF',
              fill: 'black',
              icon: 'bi-filetype-pdf',
              action: () => {
                exportReport({xlsx:false, pdf:true})
              },
            },
            {
              name: 'Export As xlsx',
              fill: 'black',
              icon: 'bi-filetype-xlsx',
              action: () => {
                exportReport({xlsx:true, pdf:false})
              },
            }
          ]
        }
      ]

    })
    const { getFoldersList, getCurrentFolder } = getFolders();
    const currentFolder = computed(() => {
      const folderList = getFoldersList(bSettings.value);
      const folderName = 'reports'
      const folder = getCurrentFolder(folderName, folderList);
      return folder
    });
    const baseFolder = computed(() => {
      const folderList = getFoldersList(bSettings.value);
      //TODO:- remove this. for now leaves folder name not coming from back
      const folderName = data.value.config.base_folder || 'leaves'
      const folder = getCurrentFolder(folderName, folderList)
      return folder
    })
    const folderFields = computed(() => {
      const cloneOfFolderFields = JSON.parse(JSON.stringify(currentFolder.value.fields));
      const emptyFolderFields = getNullObject(cloneOfFolderFields)
      return emptyFolderFields
    })
    provide(keys.folderFields, folderFields)
    function sortTheList() {
      sortingFlag.value = !sortingFlag.value
      getReport(uiFilters.value);
    }
     async function applyFilters() {
      showFilters.value = false
      enteredFilter.value = uiFilters.value.map((each) => {
        const type = each?.filterType;
        const fieldName = each?.fieldOptions?.name;
        const enteredValue = formData.value[fieldName];
        if (enteredValue != null) {
          if (type === 'docpicker') {
            if (each.is_multiple) {
              //using valueCache to keep the list of values already applied
              //using value to send just the id in txn
              each.value = Array.isArray(each.value) > 0 ? each.value : []
              each.valueCache = Array.isArray(each.valueCache) ? each.valueCache : [];
              //toAdd and toRemove flags coming from docp control
              const toAdd = enteredValue.value.filter((each) => each.toAdd == true)
              const toRemove = enteredValue.value.filter((each) => each.toRemove == true)
              if (toAdd.length > 0) {
                each.valueCache.push(...toAdd);
                each.value.push(...toAdd.map((each) => each.id));
              }
              toRemove.forEach((x) => {
                const cacheIndex = each.valueCache.findIndex((obj) => obj.id === x.id);
                if (cacheIndex !== -1) {
                  each.valueCache.splice(cacheIndex, 1);
                }
                const valueIndex = each.value.indexOf(x.id);
                if (valueIndex !== -1) {
                  each.value.splice(valueIndex, 1);
                }
              });
              //after processing,setting params.value to the desired array
              //ui prefilled data
              params.value[fieldName] = each.valueCache

            }
            else {
              if (!enteredValue.value?.[0]?.toBeDeleted) {
                each.value = enteredValue.value?.[0]?.id;
                params.value[fieldName] = enteredValue.value?.[0];
              }
              else {
                each.value = ''
                params.value[fieldName] = ''
              }
            }


          } else {
            each.value = enteredValue?.value || '';
            params.value[fieldName] = enteredValue.value;
          }
        }
        return each;
      })
      await getReport(enteredFilter.value)
      formData.value = {}
      toast.success('filter applied', {
        'autoClose': 700,
        position: toast.POSITION.TOP_CENTER,
      })
    }


    async function exportReport(fileType) {
      // const notificationId = toast.loading('exporting...', {
      //   position: toast.POSITION.TOP_CENTER,
      //   className: 'toast-body',
      // })
      const txnExportReport = currentFolder.value.txns.txn_reports_export_report
      const params = {
        swhandle: swhandle.value,
        report_id: data.value.id,
        template_id: data.value.templates_id[0].id,
        ui_filters :uiFilters.value,
        fileType:fileType
      }
      const sessionId = store.getters['sessionIdGetter'];
      txnExportReport.session_key = sessionId;
      txnExportReport.params = params
      const retVal = await sendMessage(txnExportReport)
      const fileName = retVal.output.filename
      // if (retVal.output.type === 'success') {
      //   toast.update(notificationId, {
      //     render: 'done',
      //     autoClose: 500,
      //     closeButton: true,
      //     type: 'success',
      //     isLoading: false
      //   })
      // }
      const {fileServePath} = usePaths()
      const url = `${fileServePath}/reports/${fileName}`
      // Open the PDF file in a new tab
      window.open(url, '_blank')


    }


    function toggleFilterView() {
      if (showFilters.value) {
        showFilters.value = false
      }
      else {
        showFilters.value = true
      }
    }
    function getTheReport(paginationParams) {
      loading.value = true
      limit.value = paginationParams.limit
      offset.value = paginationParams.offset
      getReport(enteredFilter.value)
    }
    async function getReport(filtersToSend) {
      //txn params send
      const txnReportGet = currentFolder.value.txns.txn_reports_get_report
      const params = {
        swhandle: swhandle.value,
        report_id: data.value.id,
        template_id: data.value.templates_id[0].id,
        ui_filters :filtersToSend ? filtersToSend : data.value.config.ui_filters,
        limit:limit.value,
        offset:offset.value,
        sortFlag : sortingFlag.value

      }
      const sessionId = store.getters['sessionIdGetter'];
      txnReportGet.session_key = sessionId;
      txnReportGet.params = params

      const retVal = await sendMessage(txnReportGet)
      recordsShowing.value = retVal?.output?.currRows
      totalRowsCount.value = retVal?.output?.totalRows
      loading.value = false
      reportTemplate.value = retVal?.output.html
    }

    function performAction(action) {
      console.log('home actions handle')
      action()
    }

    //Hooks
    onMounted(() => {
      getReport(uiFilters.value);

    });
    return {
      actions,
      reportTemplate,
      data,
      baseFolder,
      performAction,
      showFilters,
      toggleFilterView,
      filterIcon,
      applyFilters,
      params,
      exportReport,
      getTheReport,
      loading,
      totalRowsCount,
      recordsShowing,
      fieldsToDisplay,
      filterApplicable,
      sortTheList,
      sortIcon
    }
  }
}
</script>

<style scoped>

.action-list {
  color: white;
  padding:10px;
  background-color: var(--toolbar-color);
  font-size: 15px;
}

.filter-button{
  width: fit-content;
  height: 30px;
  font-size: 0.8rem;
  font-weight: 600;
}


.report-view {
  margin: auto;
  width: 100%;
  padding:10px;
  background-color: white;
  height: 75vh;
  overflow: scroll;

}
.report-view::-webkit-scrollbar {
  display: none;
}

pre{
  scrollbar-width: none !important;
}

.filter-box {
  position: fixed;
  border-bottom: 1px solid black;
 background-color: var(--controls-background-color) !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 96%;
  height: fit-content;
  padding-left: 10px;
  padding-bottom:10px;
}

</style>
