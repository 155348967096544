<template>
  <div class="custom-header">
    <span>{{ label }}</span>
    <div v-if="isRegisterView">
      <div
        class="emp-label"
      >
        <div
          class="label-inner-container"
        >
          <button
            v-if="isRO || isApprovalManager"
            class="arrow-button"
            :disabled="isFirstEmployee"
            @click="previousEmployee"
          >
            <svg
              data-v-b800c10e=""
              class="ov-icon mt-1"
              aria-hidden="true"
              width="28.8"
              height="28.8"
              viewBox="-1.6 -1.6 19.2 19.2"
              fill="#2f688d"
              style="font-size: 1.8em;"
            >
              <path
                d="M3.86 8.753l5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 00-1.659-.753l-5.48 4.796a1 1 0 000 1.506z"
                fill="#2f688d"
              />
            </svg>
          </button>

          <span class="label-content">{{ selectedEmployee?.label }}</span>
          <button
            v-if="isRO || isApprovalManager"
            class="arrow-button"
            :disabled="isLastEmployee"
            @click="nextEmployee"
          >
            <svg
              data-v-b800c10e=""
              class="ov-icon mt-1"
              aria-hidden="true"
              width="28.8"
              height="28.8"
              viewBox="-1.6 -1.6 19.2 19.2"
              fill="#2f688d"
              style="font-size: 1.8em;"
            >
              <path
                d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 011.659-.753l5.48 4.796a1 1 0 010 1.506z"
                fill="#2f688d"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <div
      :class="isRegisterView ? 'right-position' : 'left-position'"
    >
      <WfmDateSwitcher
        :is-week-picker="isWeekPicker"
        @update-month-year="updateMonthYear"
      />
    </div>
    <div
      class="actions"
    >
      <label v-if="gridView === 'attendance'">
        <input
          type="checkbox"
          :checked="isRegisterView"
          @change="showRegisterView($event)"
        >
        <span
          style="font-size: 12px"
        >Show Register View</span>
      </label>
      <label v-if="gridView === 'attendance' && !isRegisterView">
        <input
          type="checkbox"
          @change="showInOut($event)"
        >
        <span
          style="font-size: 12px"
        >Show in/out time</span>
      </label>
      <div class="action-container">
        <div
          v-if="!isRegisterView"
          class="action-button"
          @click="removeEmptyRows"
        >
          <button>
            Remove row
          </button>
        </div>
        <div
          class="action-button"
          @click="clearSelections"
        >
          <button>
            Deselect all
          </button>
        </div>
        <div
          v-if="isApprovalManager && gridView === 'allocations'"
          class="action-button"
          @click="deleteAllocations"
        >
          <button>
            Delete Cell
          </button>
        </div>
        <div
          v-if="isApprovalManager && gridView === 'attendance'"
          class="action-button"
          @click="approveAttendanceCells"
        >
          <button>
            Approve
          </button>
        </div>
        <div
          v-if="isApprovalManager && gridView === 'attendance'"
          class="action-button"
          @click="rejectAttendanceCells"
        >
          <button>
            Reject
          </button>
        </div>
        <div
          v-if="!isAdmin"
          class="action-save"
          @click="saveChanges"
        >
          <button>
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="isRegisterView"
    class="register-summary"
    :style="{float: 'right', width: '25%', height: '200px', 'margin-top': '4vh'}"
  >
    <RegisterSummary
      :current-folder="currentViewFolder"
      :filtered-emp="filteredAttendanceSummary"
      :on-grid-ready="onGridReady"
      :selected-emp="selectedEmployee"
      :selected-emp-type="selectedEmployeeType"
      :register-view="isRegisterView"
      :show-pending-only="showPendingOnly"
      :show-approved-only="showApprovedOnly"
      :update-filter-values="updateFilterValues"
      :filters-data="filtersData"
      @toggle-pending-work="togglePendingWork"
      @toggle-approved-work="toggleApprovedWork"
      @update-search-query="updateSearchQuery"
      @update-selected-employee="updateSelectedEmployee"
      @update-selected-employee-type="updateSelectedEmployeeType"
    />
    <div class="text-left ms-2">
      <div class="leaves-left">
        This Month Leaves Left : <span>{{ leaveQuota?.leaves_left }}</span>
      </div>
      <div class="leaves-left">
        Yearly Leaves Left : <span>{{ leaveQuota?.yearly_leaves_left }}</span>
      </div>
    </div>
  </div>

  <div
    v-else
  >
    <FilterComponent
      :current-folder="currentViewFolder"
      :filters-data="filtersData"
      :on-grid-ready="onGridReady"
      :update-filter-values="updateFilterValues"
      :clear-selection="clearSelection"
    />
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import WfmDateSwitcher from '../../../common/wfm-dateSwitcher.vue';
import FilterComponent from './FilterComponent.vue';
import RegisterSummary from './RegisterSummary.vue';
import EmployeeTypeFilter from './EmployeeTypeFilter.vue';
import EmployeeFilter from './EmployeeFilter.vue';
import { useStore } from 'vuex';
import attendanceGridUtils from '../../../composables/attendanceGridUtils';
import allocationGridUtils from '../../../composables/allocationGridUtils';
import { toast } from 'vue3-toastify';
import getFolders from '../../../composables/getFolders';
import { sendMessage } from '../../../services/websocket.js';
export default {
  components: { WfmDateSwitcher, FilterComponent, RegisterSummary, EmployeeFilter, EmployeeTypeFilter },
  props: {
    currentFolder: {
      type: Object,
      default: () => {}
    },
    isDutyChart: {
      type: Boolean,
      default: () => false
    },
    registerView: {
      type: Boolean,
      default: () => false
    },
    filtersData: {
      type: Object,
      default: () => {}
    },
    selectedCells: {
      type: Object,
      default: () => {}
    },
    onGridReady: {
      type: Function,
      default: () => {
      }
    },
    showInOutToggle: {
      type: Function,
      default: () => {}
    },
    updateFilterValues: {
      type: Function,
      default: () => {}
    },
    clearSelection: {
      type: Function,
      default: () => {}
    },
  },
  emits: ['update-month-year', 'show-register-view', 'update-selected-employee', 'update-selected-employee-type'],
  setup(props, context) {
    const store = useStore();
    const bSettings = store.getters['bSettings'];
    const gridApi = computed(() => {
      return store.getters['agGridModule/attendanceGridApi']
    })

    const label = ref(props?.currentFolder?.label?.eng);
    const isWeekPicker = ref(props.isDutyChart);
    const isRegisterView = computed(() => {
      return props.registerView;
    }
    );
    const clearSelections = ref(props.clearSelection);
    const currentViewFolder = ref(props.currentFolder)
    const gridView = ref(currentViewFolder?.value?.name);
    const isApprovalManager = ref(false);
    const isReportingManager = ref(true);
    const isAdmin = ref(false);
    const isIndividualEmployee = ref(true);

    const showFilter = ref(true);
    const currentEmpId = bSettings?.env?.id;
    const currentEmpCode = bSettings?.env?.code;
    const currEmpDesignation = bSettings?.env?.designation
    const leaveQuota = computed(() => {
      return store.getters['commonModule/leavesQuotaGetters']
    })
    const currentEmpName = bSettings?.env?.code; // TODO env should carry name of logged in emp
    const currentEmpType = bSettings?.env?.employee_types != null ? bSettings?.env?.employee_types[0] : ''; // TODO admin should be given an employee type in env
    console.log('HeaderComponent code...')
    if (bSettings?.env?.code === 'admin') {
      isApprovalManager.value = true;
      isIndividualEmployee.value = false
    } else if (bSettings?.env?.isAO) {
      isApprovalManager.value = true;
      isIndividualEmployee.value = false
    } else if (bSettings?.env?.isRO) {
      isApprovalManager.value = true;
      isIndividualEmployee.value = false
    } else if (bSettings?.env?.isHR) {
      isApprovalManager.value = true;
      isIndividualEmployee.value = false
    }

    if (currentEmpCode === 'admin') {
      isApprovalManager.value = true;
      isIndividualEmployee.value = false
    }


    const { save, approveAttendance, rejectAttendance, deficitMinutesToHours } = attendanceGridUtils();
    const { saveAllocation, deleteAllocation } = allocationGridUtils();
    var firstLoad = true

    const selectedEmployeeIndex = computed(() => {
      if (!selectedEmployee.value) return -1;
      return filteredAttendanceSummary.value.findIndex((emp) => emp.id === selectedEmployee.value.id);
    });


    const isFirstEmployee = computed(() => selectedEmployeeIndex.value === 0);
    const isLastEmployee = computed(() => selectedEmployeeIndex.value === filteredAttendanceSummary.value.length - 1);

    const previousEmployee = () => {
      if (selectedEmployeeIndex.value > 0) {
        filterEmp(filteredAttendanceSummary.value[selectedEmployeeIndex.value - 1]);
      }
      updateSelectedEmployee(selectedEmployee.value)
    };

    // Method to select the next employee in the attendance summary
    const nextEmployee = () => {
      if (selectedEmployeeIndex.value < filteredAttendanceSummary.value.length - 1) {
        filterEmp(filteredAttendanceSummary.value[selectedEmployeeIndex.value + 1]);
      }
      updateSelectedEmployee(selectedEmployee.value)
    };

    async function saveChanges() {
      if (gridView.value === 'attendance') {
        await save(store, isRegisterView.value, gridApi.value);
        updateSelectedEmployee(selectedEmployee.value);
      } else {
        await saveAllocation(store);
      }
    }

    async function deleteAllocations() {
      const cellsSelected = props.selectedCells;
      const selectedCells = await deleteAllocation(store, cellsSelected, gridApi.value);
      props.clearSelection(selectedCells);
    }

    function showInOut(event) {
      const isChecked = event.target.checked;
      props.showInOutToggle(isChecked);
    }

    const removeEmptyRows = async() => {
      props.onGridReady();
    }

    const approveAttendanceCells = async() => {
      let isDeficit = false;
      let deficitMinutes = 0;
      let isSP = false;
      const cellsSelected = props.selectedCells;
      for (let i = 0; i < cellsSelected.length; i++) {
        const key = Object.keys(cellsSelected[i]);
        for (const obj in cellsSelected[i][key[0]]) {
          if (
            cellsSelected[i][key[0]][obj]?.deficit_minutes < 0
          ) {
            isDeficit = true;
            deficitMinutes += cellsSelected[i][key[0]][obj]?.deficit_minutes;
          }
          if (cellsSelected[i][key[0]][obj]?.att_type?.[0]?.code === 'SP') {
            isSP = true;
          }
        }
      }
      if (isSP) {
        toast.warning('Single Punch cannot be approved', {
          position: toast.POSITION.TOP_CENTER,
        });
        props.clearSelection([]);
        return false;
      }
      if (isDeficit) {
        const element = document.getElementsByClassName('alert-window');
        element[0].style.display = 'block';
        const deficitElement = document.getElementById('deficit-hours');
        deficitElement.textContent = `Total deficit hours: ${deficitMinutesToHours(deficitMinutes, true)}`;
      } else {
        const selectedCells = await approveAttendance(store, gridApi.value, cellsSelected);
        props.clearSelection(selectedCells);
        console.log(selectedCells)
      }
    }

    const rejectAttendanceCells = async() => {
      const cellsSelected = props.selectedCells;
      const selectedCells = await rejectAttendance(store, gridApi.value, cellsSelected);
      props.clearSelection(selectedCells);
      console.log(selectedCells)
    }


    function showRegisterView(event) {
      context.emit('show-register-view', event.target.checked)
    }

    //Register view

    const attendanceRows = computed(() => {
      return store.getters['agGridModule/attendanceGridRows'];
    });


    const searchQuery = ref('');
    const selectedEmployee = ref();
    const selectedEmployeeType = ref();
    const showPendingOnly = ref(true);
    const showApprovedOnly = ref(false);
    let leaveQuotaInputDate = null;
    async function updateMonthYear(monthYear) {
      context.emit('update-month-year', monthYear)
      const lastDayOfMonth = new Date(monthYear.year, monthYear.month, 0)
      leaveQuotaInputDate = lastDayOfMonth.toISOString()
      await getLeavesQuota(selectedEmployee.value?.id, leaveQuotaInputDate)
    }
    function updateSearchQuery(query) {
      searchQuery.value = query;
    }

    const { getAllFoldersList, getCurrentFolder } = getFolders();
    async function getLeavesQuota(empId, inputDate) {
      try {
        const currentEmp = empId
        let leavesQuota = [];
        const folderList = getAllFoldersList(bSettings);
        const leavesFolder = getCurrentFolder('leaves', folderList);
        const txnToGetLeaveQuota = leavesFolder?.txns?.txn_show_leaves_quota
        const txnParams = { 'refRows': true, 'employees_id': currentEmp, 'input_date': inputDate}
        txnToGetLeaveQuota.params = txnParams
        const result = await sendMessage(txnToGetLeaveQuota)
        if (result?.output?.data?.records?.[0]) {
          leavesQuota = JSON.parse(result.output.data?.records?.[0])
          store.commit('commonModule/leavesQuotaMutations', leavesQuota)
        }
      } catch (error) {
        console.log(error)
      }
    }

    async function updateSelectedEmployee(employee) {
      selectedEmployee.value = employee
      await getLeavesQuota(employee.id, leaveQuotaInputDate);
      context.emit('update-selected-employee', employee)
    }

    function makeEmployeeValue(emp) {
      return {
        label: `${emp.name} (${emp.empCode}) - ${emp.empType} - ${emp.designation} - RO: ${emp.roName} (${emp.roEmpCode})`,
        id: emp.id,
        type: emp.empType,
        designation: emp.designation,
        roName: emp.roName,
        roEmpCode: emp.roEmpCode
      }
    }
    function resetSelectedEmployee() {
      console.log('tools', filteredAttendanceSummary.value)
      const emp = filteredAttendanceSummary.value[0]
      if (emp != null) {
        // selectedValue signature copied from EmployeeFilter
        // TODO : a standard docpicker control and selected value signature ...
        const selectedValue = makeEmployeeValue(emp)
        updateSelectedEmployee(selectedValue)
      }
    }


    function updateSelectedEmployeeType(employeeType) {
      selectedEmployeeType.value = employeeType
      context.emit('update-selected-employee-type', employeeType)
      resetSelectedEmployee()
    }

    function togglePendingWork(pendingValue, approvedValue) {
      showPendingOnly.value = pendingValue;
      showApprovedOnly.value = approvedValue;
      resetSelectedEmployee()
    }
    function toggleApprovedWork(approvedValue, pendingValue) {
      showApprovedOnly.value = approvedValue;
      showPendingOnly.value = pendingValue;
      resetSelectedEmployee()
    }

    const attendanceSummary = computed(() => {
      const empWiseAttStatus = [];
      const total = attendanceRows.value?.length || 0;
      for (let i = 0; i < attendanceRows.value?.attStatus?.length; i++) {

        const obj = {
          empCode: attendanceRows.value.attStatus[i]?.data?.emp_code,
          empType: attendanceRows.value.attStatus[i]?.data?.employee_type?.eng,
          designation: attendanceRows.value.attStatus[i]?.data?.designation,
          name: attendanceRows.value.attStatus[i]?.data?.first_name?.eng + ' ' +  (attendanceRows.value.attStatus[i]?.data?.last_name?.eng || ''),
          roName: attendanceRows.value.attStatus[i]?.data?.ro_first_name?.eng + ' ' +  (attendanceRows.value.attStatus[i]?.data?.ro_last_name?.eng || ''),
          roEmpCode: attendanceRows.value.attStatus[i]?.data?.ro_emp_code,
          id: attendanceRows.value.attStatus[i]?.data.emp_id
        }
        for (let j = 0; j < attendanceRows.value.attStatus[i]?.data?.info?.length; j++) {
          const attStatus = Object.keys(attendanceRows.value.attStatus[i]?.data?.info[j])[0];
          if (attStatus == 'unapproved') {
            obj.final_approved = obj.final_approved != null ? obj.final_approved : 0;
            obj.attendanceExists = attendanceRows.value.attStatus[i]?.data?.info?.[j]?.[attStatus] > 0;
          }
          else if (attStatus == 'final_approved') {
            const approvedCount = attendanceRows.value.attStatus[i]?.data?.info?.[j]?.[attStatus];
            obj.final_approved = obj.final_approved != null ? obj.final_approved + approvedCount : approvedCount;
          }
          else if (attStatus == 'settled') {
            const settledCount = attendanceRows.value.attStatus[i]?.data?.info?.[j]?.[attStatus];
            obj.final_approved = obj.final_approved != null ? obj.final_approved + settledCount : settledCount;
          }
          else {
            obj.attendanceExists = true;
          }
        }
        obj.unapproved = total - (obj.final_approved || 0);
        obj.fully_approved = obj.final_approved == total;
        obj.empDesignation = attendanceRows.value.employees?.designation[0]?.name
        empWiseAttStatus.push(obj);
      }
      return empWiseAttStatus;
    })

    function filterEmp(emp) {
      const selectedValue = {
        label: `${emp.name} (${emp.empCode}) - ${emp.empType} - ${emp.designation} - RO: ${emp.roName} (${emp.roEmpCode})`,
        id: emp.id,
        type: emp.empType,
        designation: emp.designation,
        roName: emp.roName,
        roEmpCode: emp.roEmpCode
      }
      selectedEmployee.value = selectedValue;
      context.emit('update-selected-employee', selectedValue);
      // employeesFilterChange();
    }

    const filteredAttendanceSummary = computed(() => {
      const search = searchQuery.value.toLowerCase();
      const result =  attendanceSummary.value.filter((status) => {
        const name = status.name.toLowerCase();
        const empCode = status.empCode.toLowerCase();
        const empType = status.empType != null  ? status.empType.toLowerCase() : ''
        // Filter by search query and unapproved status based on the "Show Pending Work" button
        const matchesSearch = name.includes(search) || empCode.includes(search);
        const matchesType = selectedEmployeeType.value != '' && selectedEmployeeType.value != null ?  selectedEmployeeType.value.toLowerCase() == empType : true
        let matchesUnapprovedFilter = true;
        if (showPendingOnly.value) {
          matchesUnapprovedFilter = status.attendanceExists == true;
        }
        else if (showApprovedOnly.value) {
          matchesUnapprovedFilter = status.fully_approved == true;
        }
        return matchesSearch && matchesType && matchesUnapprovedFilter;
      });
      if (result.length > 0 && firstLoad) {
        setTimeout(resetSelectedEmployee, 200)
        firstLoad = false
      }
      return result
    });

    onMounted(() => {
      if (isIndividualEmployee.value) {
        updateSelectedEmployee(makeEmployeeValue({
          id : currentEmpId,
          empCode : currentEmpCode,
          empDesignation:currEmpDesignation,
          name: currentEmpName,
          empType: currentEmpType
        }))
      }
    })

    return {
      label,
      gridView,
      isWeekPicker,
      isRegisterView,
      showRegisterView,
      currentViewFolder,
      updateMonthYear,
      isAdmin,
      showInOut,
      isApprovalManager,
      isReportingManager,
      showFilter,
      currentEmpId,
      saveChanges,
      removeEmptyRows,
      approveAttendanceCells,
      rejectAttendanceCells,
      clearSelections,
      deleteAllocations,
      filteredAttendanceSummary,
      togglePendingWork,
      showPendingOnly,
      toggleApprovedWork,
      showApprovedOnly,
      selectedEmployee,
      selectedEmployeeType,
      updateSearchQuery,
      updateSelectedEmployee,
      updateSelectedEmployeeType,
      isFirstEmployee,
      isLastEmployee,
      previousEmployee,
      nextEmployee,
      leaveQuota

    };
  }
};
</script>

<style>
@media print{
    .register-summary{
        display:none !important;
    }
    .actions{
        display:none !important;
    }
}
.custom-header {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.right-position {
  position: absolute !important;
  left: 80vw;
  top: 11vh;
  width: 300px;
}

.custom-header div {
  cursor: pointer;
  position: relative;
}

.custom-header span {
  font-size: 20px;
  color: #292929;
  margin: 10px;
}

.emp-label {
  width: 1200px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-weight: normal;
  color: #292929;
}

.label-inner-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 2px;
  padding: 2px;
}

.label-content {
  font-size: 12px;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  margin: 10px;
}

.filter-icon {
  cursor: pointer;
  background-color: #aebbcc;
  padding: 5px;
  border-radius: 5px;
  margin: 2px;
}

.filter-icon:hover {
  background-color: #83ACC8;
}

.action-container {
  display: flex;
  align-items: center;
  color: #292929;
}
.leaves-left{
  font-weight: 700;
}
.action-button {
  background-color: #aebbcc;
  padding: 5px;
  border-radius: 5px;
  margin: 2px;
  text-wrap: nowrap;
  cursor: pointer;
}

.action-button:hover {
  background-color: #83ACC8;
}

.action-save {
  background-color: #aebbcc;
  padding: 5px;
  border-radius: 5px;
  margin: 2px;
}

.action-save:hover {
  background-color: #83ACC8;
}

</style>
