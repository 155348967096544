<template>
  <div class="container" :style="dynamicStyles">
    <div
      v-for="value in values()"
      :key="value"
    >
      <div
        v-if="value.leave_status=='final_approved'"
        style="background-color:green"
      >
        {{ value.name }} ({{ value.code }}) <span v-if="value.leave_code!=null">[{{ value.leave_code }}]</span>
      </div>
      <div
        v-else-if="value.leave_status=='rejected'"
        style="background-color:red"
      >
        {{ value.name }} ({{ value.code }}) <span v-if="value.leave_code!=null">[{{ value.leave_code }}]</span>
      </div>
      <div v-else>
        {{ value.name }} ({{ value.code }}) <span v-if="value.leave_code!=null">[{{ value.leave_code }}]</span>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
export default {
  props: ['params'],
  setup(props) {
    const store = useStore();
    const globalBaseData = store.state.dutyCharts;
    const bottomRowsData = store.state.dutyChartPinnedBottomRows;
    const rowPinned = props.params.node.rowPinned == 'bottom';
    const dynamicStyles = computed(() => ({
      overflow: rowPinned ? 'auto' : 'hidden'
    }));
    const values = () => {
      let ret = [];
      if (rowPinned) {
        const fIndex = bottomRowsData.findIndex((gd) => gd.id == props.params.data.id);
        if (fIndex > -1 && bottomRowsData[fIndex][props.params.colDef.field] != null) {
          ret = bottomRowsData[fIndex][props.params.colDef.field].employees.filter((emp) => emp.toRemove == null);
        }
      }
      else {
        const fIndex = globalBaseData.findIndex((gd) => gd.id == props.params.data.id);
        if (fIndex > -1 && globalBaseData[fIndex][props.params.colDef.field] != null) {
          ret = globalBaseData[fIndex][props.params.colDef.field].employees.filter((emp) => emp.toRemove == null);
        }
      }
      return ret;
    }
    return {
      values,
      rowPinned,
      dynamicStyles
    }
  }
};
</script>

<style scoped>

.scrollable-cell {
  width: 100%;       /* Full width of the cell */
  height: 100px;     /* Set a fixed height */
  overflow: auto;    /* Makes the content scrollable */
}

.scrollable-content {
  padding: 10px;
  background-color: #f5f5f5;
}

.container{
  height: 100%;
  width: 100%;
  text-align: 'left';
}
</style>
